import * as types from './actionTypes';
import { sideMenuItemsSet } from './sideMenuActions';

export function userSet(user: any) {
  return {
    type: types.USER_SET,
    user,
  };
}

export function userStateSet(state: any) {
  return { type: types.USER_STATE_SET, state };
}

export function userAuthoritySet(authorities: any) {
  return { type: types.USER_AUTHORITIES_SET, authorities };
}

export const setUserAndFetchAuthorities = (user: any) => {
  return (dispatch: any) => {
    dispatch(userSet(user));

    const sideMenState = {
      Fulfillment: 'hide',
      Funding: 'hide',
      ItemScheduling: 'hide',
      OfferClearing: 'hide',
    };
    for (let index = 0; index < user.authorities.length; index++) {
      const el = user.authorities[index];
      switch (el) {
        case 'CAN_FULFILL_PREORDER':
        case 'CAN_VIEW_PREORDER_AWARDS':
          sideMenState.Fulfillment = 'show';
          break;
        case 'CAN_FUND_PREORDER':
          sideMenState.Funding = 'show';
          break;
        case 'CAN_SCHEDULE_PO_ITEMS':
          sideMenState.ItemScheduling = 'show';
          break;
        case 'CAN_CLEAR_PO_OFFERS':
          sideMenState.OfferClearing = 'show';
          break;
      }
    }

    dispatch(sideMenuItemsSet(sideMenState));
  };
};
