import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { ClickAwayListener, Popper, SvgIcon } from '@material-ui/core';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  RowDataChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { differenceInDays } from 'date-fns';
import React from 'react';
import { useEffect, useState } from 'react';
import apiToUrlMap from '../../ApiMapping';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import { IDateRange } from '../../types/IPricing';
import { dataFormatting, DataFormattingType } from '../../_lib/lib';
import '../PoClearing/PricingChart.scss';

export interface IRowData {
  AVG_ABP: any;
  AVG_ASP: any;
  OFFER: any;
  BOOKED: any;
  SOLD: any;
  MCS: any;
  LABEL?: '$ List' | '$ Low' | any;
}

interface IOfferClearingDemandPopup {
  menuDimensions: {
    top: number;
    left: number;
  };
  menuOptionOpen: any;
  menuOptionClose: any;
  heights: any;
  dateRange: IDateRange;
}

interface IHeaderObj {
  ABP: number;
  ASP: number;
  BOOKED: number;
  OFFERED: number;
  SOLD: number;
}

const headerClass = (headerObj: IHeaderObj, headerId: string) => {
  let classes = 'px-wrap-text ';
  if (headerObj[`${headerId}` as keyof IHeaderObj]) {
    classes += headerObj[`${headerId}` as keyof IHeaderObj];
  }
  return classes;
};

const colArr = (heights: any) => [
  {
    id: 'AVG_ABP',
    colId: 'abpInPeriod',
    width: heights.ABP,
    type: 'currency-with-no-0',
    label: 'Average Book Price',
    headerClass: headerClass(heights, 'ABP'),
  },
  {
    id: 'AVG_ASP',
    colId: 'aspInPeriod',
    width: heights.ASP,
    type: 'currency-with-no-0',
    label: 'Average Shipping Price',
    headerClass: headerClass(heights, 'ASP'),
  },
  {
    id: 'OFFER',
    colId: 'offersInPeriod',
    width: heights.OFFERED - 10,
    type: 'integer',
    label: 'Offered',
    headerClass: headerClass(heights, 'OFFERED'),
  },
  {
    id: 'BOOKED',
    colId: 'bookedShippedInPeriod',
    width: heights.BOOKED + 10,
    type: 'integer',
    label: 'Booked',
    headerClass: headerClass(heights, 'BOOKED'),
  },
  {
    id: 'SOLD',
    colId: 'soldInPeriod',
    width: heights.SOLD + 5,
    label: 'Shipped',
    type: 'integer',
    headerClass: headerClass(heights, 'SOLD'),
  },
];

function OfferClearingDemandPopup({
  menuDimensions,
  menuOptionOpen,
  menuOptionClose,
  heights,
  dateRange,
}: IOfferClearingDemandPopup) {
  const [popupWidthState, setPopupWidthState] = useState({
    width: 0,
    left: 0,
    top: 0,
  });

  // use data service
  const { openSnackBar, fetchUrl } = useDataService();

  const [subGridAPI, setSubGridAPI] = useState<GridApi>();

  const getTopPinnedRows = (params: RowDataChangedEvent) => {
    const data = menuOptionOpen.data;
    const group = menuOptionOpen.demandData;

    // pinned row data
    const topRowPinnedRows: IRowData = {
      AVG_ABP: data.analytics.salesAbp,
      AVG_ASP: dataFormatting('currency', data.analytics.salesAsp),
      BOOKED: data.analytics.salesBooked,
      OFFER: data.analytics.salesOffered,
      SOLD: data.analytics.salesSold,
      MCS: null,
    };

    // if is group then get data from demand data
    if (data.isGroup && group) {
      topRowPinnedRows.AVG_ABP = group.abpInPeriod;
      topRowPinnedRows.AVG_ASP = dataFormatting('currency', group.aspInPeriod);
      topRowPinnedRows.BOOKED = group.bookedShippedInPeriod;
      topRowPinnedRows.OFFER = group.offersInPeriod;
      topRowPinnedRows.SOLD = group.soldInPeriod;
    }

    params.api.setPinnedTopRowData([topRowPinnedRows]);
    params.api.hideOverlay();
  };
  // TODO: load rowData data dynamically
  const gridOptions: GridOptions = {
    headerHeight: 0,
    getRowHeight: (params: ICellRendererParams) => {
      if (params.node.isRowPinned()) return 38;
      return 24;
    },
    defaultColDef: {
      flex: 1,
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
    },
    enableCellTextSelection: true,
    suppressContextMenu: true,
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowHoverHighlight: true,
    animateRows: true,
    onGridReady: (params: GridOptions) => {
      if (params.api) setSubGridAPI(params.api);
    },
    onRowDataChanged: getTopPinnedRows,
  };

  useEffect(() => {
    if (menuDimensions.top && menuDimensions.left && subGridAPI) {
      let width: number = 130;
      let subColDefs: Array<ColDef | ColGroupDef> = [
        {
          headerName: '',
          suppressSizeToFit: true,
          width: 147,
          minWidth: 147,
          maxWidth: 147,
          field: 'LABEL',
          headerClass: 'px-display-none',
          pinnedRowCellRendererFramework: (params: ICellRendererParams) => {
            return (
              <>
                <p>
                  <SvgIcon
                    className="pointer-mouse demand-chart-icon"
                    onClick={menuOptionClose}
                    color="primary">
                    <svg
                      className="MuiSvgIcon-root jss178 padding-0"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true">
                      <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path>
                    </svg>
                  </SvgIcon>
                  <span className="demand-chart">DEMAND CHART</span>
                </p>
              </>
            );
          },
        },
      ];

      colArr(heights).forEach((columnState) => {
        width += columnState.width;
        const colDef: ColDef = {
          headerName: columnState.label,
          width: columnState.width,
          minWidth: columnState.width,
          maxWidth: columnState.width,
          field: columnState.id,
          colId: columnState.id,
          headerClass: columnState.headerClass,
          cellClass: columnState.headerClass,
        };

        if (columnState.id !== 'AVG_ASP') {
          colDef.valueFormatter = (params: ValueFormatterParams) => {
            if (!params.value) return '--';
            return dataFormatting(columnState.type as DataFormattingType, params.value);
          };
        }

        if (columnState.id) {
          colDef.valueFormatter = (params: ValueFormatterParams) => {
            if (params.value === null || undefined) return '--';
            return params.value;
          };
        }

        if (columnState.id === 'AVG_ABP') {
          colDef.sort = 'desc';
          colDef.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (valueA === valueB) {
              if (nodeA.data.MCS) return 1;
              if (nodeB.data.MCS) return -1;
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          };
        }
        subColDefs.push(colDef);
      });

      setPopupWidthState({
        width: width || 583,
        top: menuDimensions.top,
        left: menuDimensions.left,
      });

      setTimeout(() => {
        if (Array.isArray(subColDefs)) subGridAPI?.setColumnDefs(subColDefs);
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subGridAPI, menuDimensions]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        let url = !!menuOptionOpen.data.isGroup
          ? apiToUrlMap.groupAnalytics
          : apiToUrlMap.itemsAnalytics;
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        const dateFormats = {
          startDate: 'd LLL yyyy',
          endDate: 'd LLL yyyy',
        };
        if (startDate.getFullYear() !== endDate.getFullYear()) {
          dateFormats.startDate = 'd LLL yyyy';
        }
        let body = {};
        if (!!menuOptionOpen.data.isGroup) {
          body = {
            dateRange: differenceInDays(endDate, startDate).toString(),
            groupName: menuOptionOpen.data.groupName,
          };
        } else {
          body = {
            dateRange: differenceInDays(endDate, startDate).toString(),
            itemNumber: menuOptionOpen.data.itemNumber,
            warehouse: menuOptionOpen.data.warehouse,
          };
        }

        // fetchFn data
        const res: any | Array<any> = await fetchUrl('POST', url, {
          body,
        });

        const subGridData: Array<IRowData> = [];
        const integerVals: any = [];
        let arr = [];
        const listPrice =
          menuOptionOpen.data.isGroup && !Array.isArray(res)
            ? res.listPrice
            : menuOptionOpen.data.listPrice;

        const lowPrice =
          menuOptionOpen.data.isGroup && !Array.isArray(res)
            ? res.lowPrice
            : menuOptionOpen.data.lowPrice;

        arr = Array.isArray(res) ? res : res.priceStatistics;
        arr.forEach((val: any) => {
          integerVals.push(+val.price);
          const data = {
            AVG_ABP: val.price,
            AVG_ASP: null,
            BOOKED: val.bookedCount,
            OFFER: val.offerCount,
            SOLD: val.soldCount,
            MCS: null,
            LABEL: undefined,
          };

          if (val.price === listPrice) data.LABEL = dataFormatting('currency', ' List');

          if (val.price === lowPrice) data.LABEL = dataFormatting('currency', ' Low');

          subGridData.push(data);
        });

        if (!integerVals.includes(listPrice)) {
          subGridData.push({
            AVG_ABP: listPrice,
            AVG_ASP: null,
            BOOKED: null,
            OFFER: null,
            SOLD: null,
            MCS: null,
            LABEL: dataFormatting('currency', ' List'),
          });
        }

        if (!integerVals.includes(lowPrice)) {
          subGridData.push({
            AVG_ABP: lowPrice,
            AVG_ASP: null,
            BOOKED: null,
            OFFER: null,
            SOLD: null,
            MCS: null,
            LABEL: dataFormatting('currency', ' Low'),
          });
        }

        subGridAPI?.setRowData(subGridData);
      } catch (error: any) {
        openSnackBar('Something went wrong. Try again.', eMessageType.error);
        subGridAPI?.setRowData([]);
      }
    };

    if (dateRange.startDate && dateRange.endDate && menuOptionOpen.data && subGridAPI) {
      fetchMenuData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, menuOptionOpen.data, subGridAPI]);

  const rowHeight = menuOptionOpen.data.isGroup ? 30 : 28;

  return (
    <Popper
      id="demand-menu"
      open={true}
      placement="bottom-start"
      disablePortal={true}
      modifiers={{
        preventOverflow: {
          enabled: true,
          fn: (params: any) => {
            const pricingGrid = document.getElementById('pxOfferClearingGrid');
            if (pricingGrid && params.popper.top < pricingGrid.offsetTop + 40) {
              params.styles.display = 'none';
            }
            return params;
          },
        },
        flip: {
          enabled: false,
        },
        arrow: {
          enabled: true,
        },
        keepTogether: {
          enabled: true,
        },
        offset: {
          offset: `-120, -${rowHeight}`,
        },
        hide: { enabled: false },
      }}
      keepMounted={false}
      transition
      anchorEl={menuOptionOpen.currentTarget}>
      <ClickAwayListener
        onClickAway={(e) => {
          menuOptionClose();
        }}>
        <div
          className="ag-theme-alpine stocklist-demand-analytics-popup"
          style={{ width: popupWidthState.width, height: '275px' }}>
          <AgGridReact gridOptions={gridOptions}></AgGridReact>
        </div>
      </ClickAwayListener>
    </Popper>
  );
}

export default OfferClearingDemandPopup;
