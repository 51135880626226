import { IUniqueFilterSpecificData } from './IPreOrders';

export enum IPoClearingTabs {
  all = 'ALL',
  awarded = 'AWARDED',
  reofferedRequest = 'REOFFER_REQUEST',
  offered = 'AWAITING_REVIEW',
}

export interface IInventoryMatrics {
  [key: string]: IInventoryMatricsInfo;
}
export interface ISalesMatrics {
  [key: string]: ISalesMatricsInfo;
}
export interface ISalesMatricsInfo {
  abpInPeriod: string;
  aspInPeriod: string;
  bookedInPeriod: number;
  bookedShippedInPeriod: number;
  currentAvailable: number;
  currentBooked: number;
  currentBookedToday: number;
  currentOffers: number;
  formattedAbpInPeriod: string;
  formattedAspInPeriod: string;
  offersInPeriod: number;
  soldInPeriod: number;
}

export interface IInventoryMatricsInfo {
  startingInventory: number;
  added: number;
  sold: number;
  endingInventory: number;
  inventoryDays: number | null;
}

export interface IOffer {
  awardedPrice: number;
  awardedQuantity: number;
  isTakeAll: number;
  itemElectId: number;
  offerId: number;
  quantityAvailable: number;
  reofferRequestPrice: null;
  reofferRequestQuantity: null;
  status:
    | 'AWARDED'
    | 'REOFFER_REQUEST'
    | 'AWAITING_REVIEW'
    | 'REOFFER_AWAITING_REVIEW'
    | 'REJECTED';
  buyerId: number;
  buyerName: string;
  offerPrice: number;
  offerQuantity: number;
}

export interface IOfferItemAnalytics {
  inventoryAdded: number;
  inventoryEnded: number;
  inventoryDays: number;
  inventorySold: number;
  inventoryStarted: number;
  salesAbp: number;
  salesAsp: number;
  salesBooked: number;
  salesOffered: number;
  salesSold: number;
}
export interface IOfferItem {
  childIdsSelected?: Array<number>;
  IsShipping: boolean;
  analytics: IOfferItemAnalytics;
  awardedAveragePrice: number;
  awardedQuantity: number;
  bandMaterial: string;
  casingMaterial: string;
  casingSize: string;
  category: string;
  color: string;
  cpuModel: string;
  displayGroupName: string;
  grade: string;
  groupName: string;
  itemElectId: number;
  itemNumber: string | number;
  listPrice: number;
  lockStatus: string;
  lowPrice: number;
  manufacturer: string;
  model: string;
  modelNumber: string;
  offerEndDate: string;
  offerStartDate: string;
  offers: Array<IOffer>;
  protocol: string;
  quantityAvailable: number;
  ram: string;
  salesTotalDemand: number;
  screenSize: string;
  shippingEndDate: string;
  shippingStartDate: string;
  warehouse: string;
}

export interface IPoClearing {
  buyerFilterLabel: IUniqueFilterSpecificData<string> | null;
  inventoryMetricsForAllGroups: IInventoryMatrics;
  offerClearing: Array<IOfferItem>;
  salesMetricsForAllGroups: ISalesMatrics;
  uniqueBuyersList: IUniqueFilterSpecificData<boolean> | null;
  poClearingActions: any;
  uniqueCategories: Array<string> | null;
  uniqueWarehouses: Array<string> | null;
}

export interface IOffersAndAnalytics {
  items: Array<IOfferItem>;
  inventoryMetricsForAllGroups: IInventoryMatrics;
  salesMetricsForAllGroups: ISalesMatrics;
}
export interface IBuyers {
  buyerId: number;
  buyerName: string;
  buyerNumber: string;
}
interface IBuyerData {
  buyerName: string;
  buyerNumber: string;
}

export interface IAllBuyerData {
  [key: number]: IBuyerData;
}

export type ItabStateCounts = {
  [key: string]: number;
};
