import { cloneDeep, some } from 'lodash';
import { Dispatch } from 'react';

import { AllTypes, awards, awardsKey, IStateHandler } from '../../../types/IPreOrders';

import {
  dateFormats,
  errorMessages,
  filterDataImpl,
  getAggregatedSumOfFields,
  getDataToUpdate,
  manipulateDates,
} from '../Utils';

export const numericFields = [
  'awardedQuantity',
  'awardedPrice',
  'takeAllLimitPercentage',
  'quantityReadyToFulfill',
];
export const noValidationFields = ['awardedQuantity', 'awardedPrice', 'isFundingRequired'];
export const takeAllFields = ['isTakeAll', 'takeAllLimitPercentage'];
export const dateFields = ['shippingStartDate', 'shippingEndDate'];
export const autoRoundOffFields = ['awardedQuantity', 'quantityReadyToFulfill'];

export const isTakeAllValue: { [key: string]: boolean } = {
  Y: true,
  N: false,
};

export const isFundingRequired: { [key: string]: boolean } = {
  Yes: true,
  No: false,
};

const checkIfNewChangeExists = (data: awards, value: AllTypes, colId: awardsKey) => {
  if (colId === 'isFundingRequired') return true;
  if (colId === 'isTakeAll') return data[colId] !== (value === 'Y' ? true : false);
  if (dateFields.includes(colId))
    return (
      data[colId] !==
      manipulateDates({
        date: value as string,
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.monthIn3Letters,
        manipulate: { days: 0 },
      })
    );

  // eslint-disable-next-line eqeqeq
  return data[colId] != value;
};

const checkItemAlreadyStatus = (
  valueToUpdate: awards[],
  itemElectId: number,
  buyerGrantId: number
) => some(valueToUpdate, { itemElectId, buyerGrantId });

export const checkIfNativeItemStatus = (valueToUpdate: awards[], itemNumberWarehouse: string) =>
  some(valueToUpdate, { itemNumberWarehouse });

export const checkIfMemorizeNeeded = (colId: string, lastColId: string, type: string) => {
  if (
    type === 'shippingDatesValidation' &&
    dateFields.includes(colId) &&
    dateFields.includes(lastColId)
  )
    return true;
  if (
    type === 'quantityReadyToFulFillValidation' &&
    numericFields[3] === colId &&
    numericFields[3] === lastColId
  )
    return true;
  if (
    type === 'isTakeAllValidation' &&
    takeAllFields.includes(colId) &&
    takeAllFields.includes(lastColId)
  )
    return true;
  return false;
};

export const resetAllStates = (stateHandler: IStateHandler[]) => {
  stateHandler.forEach((state: IStateHandler) => {
    state.setError('');
    state.setValue(state.value);
  });
};

export const preprocessValue = (colId: string, value: AllTypes, data: awards) => {
  const changesExists = checkIfNewChangeExists(data, value, colId as awardsKey);
  if (isNaN(value as number) && numericFields.includes(colId))
    return {
      changesExists,
      isValid: false,
      message: errorMessages.updateAwards.notNumeric,
      processedValue: value,
    };
  if (
    parseInt(value as string) <= 0 &&
    numericFields.includes(colId) &&
    colId !== numericFields[3]
  ) {
    let message =
      colId === 'takeAllLimitPercentage'
        ? errorMessages.updateAwards.takeAllLimitValidation
        : errorMessages.updateAwards.negativeNumber;
    return {
      changesExists,
      message,
      isValid: false,
      processedValue: parseInt(value as string),
    };
  }
  if (dateFields.includes(colId)) {
    const formattedDate = manipulateDates({
      date: value as string,
      formatTo: dateFormats.yearMonthDate,
      currentFormat: dateFormats.monthIn3Letters,
      manipulate: { days: 0 },
    });
    return {
      changesExists,
      isValid: true,
      message: errorMessages.updateAwards.dateValidation,
      processedValue: formattedDate,
    };
  }
  let processedValue;
  if (colId === 'isTakeAll') processedValue = isTakeAllValue[value as string];
  else if (colId === 'isFundingRequired') processedValue = value;
  else processedValue = +parseFloat(value as string).toFixed(2);
  return {
    changesExists,
    processedValue,
    isValid: true,
    message: errorMessages.updateAwards.default,
  };
};

export const getValueRespectiveToIncrementSize = (value: number, data: awards, field: string) => {
  const { incrementSize, quantityFulfilled } = data;
  if (field === 'quantityReadyToFulfill' && value % incrementSize !== 0) {
    if (incrementSize === 1) return { value, isAutoRounded: false };
    const excessModuledValue = value % incrementSize;
    const isAutoRounded = excessModuledValue === 0 ? false : true;
    return { isAutoRounded, value: value - excessModuledValue };
  }
  if (field === 'awardedQuantity') {
    if (value <= quantityFulfilled && quantityFulfilled !== 0) {
      const isAutoRounded = incrementSize !== 1 ? true : false;
      return { isAutoRounded, value: quantityFulfilled };
    } else if (value >= quantityFulfilled && value % incrementSize > 0) {
      const excessModuledValue = (value - quantityFulfilled) % incrementSize;
      const isAutoRounded = excessModuledValue === 0 ? false : true;
      return { isAutoRounded, value: value - excessModuledValue };
    }
  }
  return { value, isAutoRounded: false };
};

export const getValueToUpdateHandler = (
  valueToUpdate: awards[],
  data: awards,
  newChanges: { [key in awardsKey]: any }
) => {
  const awards = getDataToUpdate(data, true);
  let newValueToUpdate: awards[] = [];
  const available = checkItemAlreadyStatus(valueToUpdate, data.itemElectId, data.buyerGrantId);
  if (available) {
    newValueToUpdate = valueToUpdate.map((item: awards) => {
      if (item.itemElectId === data.itemElectId && item.buyerGrantId === data.buyerGrantId) {
        return {
          ...item,
          ...newChanges,
        };
      }
      return item;
    });
  } else {
    newValueToUpdate = valueToUpdate.concat({
      ...awards,
      ...newChanges,
    });
  }
  return newValueToUpdate;
};

export const getNewStateHandler = (
  stateHandler: IStateHandler[],
  colId: string,
  newValue: AllTypes,
  buyerNumber: string,
  itemNumberWarehouse: string,
  message: string,
  setError: Dispatch<string>,
  setValue: Dispatch<AllTypes>,
  isAutoRounded: boolean
) => {
  const newStateHandler = cloneDeep(stateHandler);
  const available = some(newStateHandler, { buyerNumber, colId });
  const newState = {
    setError,
    setValue,
    buyerNumber,
    itemNumberWarehouse,
    message,
    value: newValue,
    colId: colId,
    isAutoRounded,
  };
  if (available) {
    return newStateHandler.map((state: IStateHandler) => {
      if (state.buyerNumber === buyerNumber && state.colId === colId) return newState;
      return state;
    });
  }
  return stateHandler.concat(newState);
};

export const getAggregatedNewFulfilledValidation = (
  valueToUpdate: awards[],
  data: awards,
  value: AllTypes
) => {
  const quantityReadyToFulfillValues: { [key: string]: number } = {};
  data.subGridByItem!.forEach(
    (item: awards) => (quantityReadyToFulfillValues[item.buyerNumber] = item.quantityReadyToFulfill)
  );
  valueToUpdate.forEach(
    (item: awards) =>
      (quantityReadyToFulfillValues[item.buyerNumber!] = item.quantityReadyToFulfill)
  );
  quantityReadyToFulfillValues[data.buyerNumber] = parseInt(value as string);
  const aggregatedSum: number = Object.values(quantityReadyToFulfillValues).reduce(
    (sum: number, curr: any) => sum + curr,
    0
  );
  return aggregatedSum <= data.subGridByItem![0].quantityAvailable;
};

export const validateAwarded = (data: awards, value: number) => {
  const endingFulfilled = data.quantityReadyToFulfill + data.quantityFulfilled;
  let newValueWithTakePercent = value;
  if (data.isTakeAll)
    newValueWithTakePercent = Math.floor(value + value * (data.takeAllLimitPercentage / 100));
  return newValueWithTakePercent >= endingFulfilled;
};

export const checkIfEndingFulfilledLessThanAwarded = (
  valueToUpdate: awards[],
  stateHandler: IStateHandler[]
) => {
  let newValueToUpdate = valueToUpdate,
    newStateHandler: IStateHandler[] = [],
    isValid = true;
  for (let index = 0; index < valueToUpdate.length; index++) {
    const {
      quantityReadyToFulfill,
      quantityFulfilled,
      awardedQuantity,
      takeAllLimitPercentage,
      buyerNumber,
      itemNumberWarehouse,
    } = valueToUpdate[index];
    const [filteredState] = filterDataImpl({
      data: stateHandler,
      filterToApply: { buyerNumber, itemNumberWarehouse },
    });
    let message: string = '';
    const endingFulfilled = quantityFulfilled + quantityReadyToFulfill;
    let awardedQuantityWithTakeAll = Math.floor(
      awardedQuantity + awardedQuantity * (takeAllLimitPercentage / 100)
    );
    if (endingFulfilled > awardedQuantityWithTakeAll && filteredState) {
      message = errorMessages.updateAwards.newFulfilledAwardedValidation;
      const { setError, setValue, isAutoRounded } = filteredState;
      newStateHandler = getNewStateHandler(
        newStateHandler,
        'quantityReadyToFulfill',
        quantityReadyToFulfill,
        buyerNumber,
        itemNumberWarehouse,
        message,
        setError,
        setValue,
        isAutoRounded
      );
      isValid = false;
    }
  }
  return { newValueToUpdate, newStateHandler, isValid };
};

export const checkIfItemHasFunds = (
  data: awards,
  previousValues: awards[],
  roundedValue: number
) => {
  const {
    quantityReadyToFulfill,
    isFundingRequired,
    startingFund,
    subGridByBuyer,
    buyerNumber,
    itemNumberWarehouse,
  } = data;
  if (roundedValue <= quantityReadyToFulfill || isFundingRequired === 'No') return true;

  const subGrid = subGridByBuyer?.map((item: awards) => {
    const updatedItem = previousValues.find(
      (currentItem: awards) =>
        currentItem.buyerNumber === item.buyerNumber &&
        currentItem.itemNumberWarehouse === item.itemNumberWarehouse
    );
    if (!updatedItem) return item;
    return { ...item, ...updatedItem };
  });

  const newStartingFund = startingFund;

  const cummulatedEndingFund = getAggregatedSumOfFields({
    dataSet: subGrid ?? [],
    middlewareFn: (item: awards) => {
      let endingFund = 0;
      if (item.buyerNumber === buyerNumber && item.itemNumberWarehouse === itemNumberWarehouse)
        endingFund = item.awardedPrice * roundedValue;
      else endingFund = item.quantityReadyToFulfill * item.awardedPrice;
      return endingFund;
    },
  });

  const newEndingFund = newStartingFund - cummulatedEndingFund;

  const positiveFunds = newStartingFund >= 0 && newEndingFund >= 0;
  return positiveFunds;
};
export const initialState = {
  validationStatus: 'no-new-changes',
  valueToUpdate: [],
  stateHandler: [],
  lastModifiedField: 'N/A',
};
