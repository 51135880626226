import React, { useEffect, useState } from 'react';
import { MenuItem, Link, TextField, Popper, ClickAwayListener, MenuList } from '@material-ui/core';
import {
  convertSecondsToTime,
  dateFormats,
  formatDatesByMonthDay,
  manipulateDates,
} from '../PreOrders/Utils';
import { find, isEqual } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ICreateDateRange } from '../../types/IPricing';
import CreateDateAnalyticsSelector from './CreateDates/CreateDateAnalyticsSelector';
import moment from 'moment';
import { ICellRendererParams } from 'ag-grid-community';
import { ISchedules } from '../../types/IItemSetup';
import { GridApi } from '@ag-grid-community/all-modules';

interface IOfferShipDate extends ICellRendererParams {
  updateItem: any;
  setLoadingDialog: any;
  allItemSchedules: any;
  gridApi: GridApi;
  offeringTimeRange: any;
}

const OfferShipDateComponent = (props: IOfferShipDate) => {
  const [showCalender, setShowCalender] = useState<boolean>(false);
  const [selectedValue] = useState<ISchedules>(props.value?.[0]);
  const [overLapShipping, setOverLapShipping] = useState<any>();
  const [dateRange, setDateRange] = useState<ICreateDateRange>({
    offerDates: {
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    },
    shippingDates: {
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    },
  });

  useEffect(() => {
    const commonDateFormat = 'YYYY-MM-DD';
    const todaysDate = moment().format(commonDateFormat);
    const daysToFinalize = props.data.daysToFinalize;
    const shippingStartFromToday = moment()
      .add(daysToFinalize + 1, 'days')
      .format('YYYY-MM-DD');
    const schedules: any = find(props.allItemSchedules.current, [
      'itemNumberWarehouse',
      props.data.itemNumberWarehouse,
    ]);
    const offerSchedules = schedules?.distinctOverlappingOfferSchedules;
    const shippingSchedules = schedules?.distinctOverlappingShippingSchedules;
    let offerDates = dateRange.offerDates;
    let shippingDates = dateRange.shippingDates;
    if (
      offerSchedules?.length &&
      moment(offerSchedules[0].offerEndDate, commonDateFormat).isSameOrAfter(todaysDate)
    ) {
      offerDates = {
        startDate: manipulateDates({
          date: offerSchedules[0].offerEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
        endDate: manipulateDates({
          date: offerSchedules[0].offerEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
      };
    }
    if (
      shippingSchedules?.length &&
      moment(shippingSchedules[0].shippingEndDate, commonDateFormat).isSameOrAfter(
        shippingStartFromToday
      )
    ) {
      shippingDates = {
        startDate: manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
        endDate: manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 2 },
        }),
      };
      setOverLapShipping(
        manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        })
      );
    }
    setDateRange({ offerDates, shippingDates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const changeSelection = async (e: any, option: any) => {
    if (isEqual(selectedValue, option) || !option) return handleClose();
    await saveDates(option);
  };

  if (!props.value || props.value?.length === 0) return '';

  const closeAllDialogs = () => {
    handleClose();
    setShowCalender(false);
  };

  const saveDates = async (selectedDates: any) => {
    const updatedStartDate = convertSecondsToTime(
      props.offeringTimeRange?.offerStartTimeInSeconds ?? 0,
      'YYYY-MM-DD HH:mm:ss',
      selectedDates.offerStartDate,
      'YYYY-MM-DD'
    );
    const updatedEndDate = convertSecondsToTime(
      props.offeringTimeRange?.offerEndTimeInSeconds ?? 0,
      'YYYY-MM-DD HH:mm:ss',
      selectedDates.offerEndDate,
      'YYYY-MM-DD'
    );
    const currentData = [
      {
        itemElectId: props.data.itemElectId,
        itemNumberWarehouse: props.data.itemNumberWarehouse,
        itemNumber: props.data.itemNumber,
        warehouse: props.data.warehouse,
        quantityForecast: props.data.quantityForecast,
        offerStartDate: updatedStartDate,
        offerEndDate: updatedEndDate,
        shippingStartDate: selectedDates.shippingStartDate,
        shippingEndDate: selectedDates.shippingEndDate,
        status: props.data.status,
      },
    ];
    if (props.updateItem) {
      const res = await props.updateItem(currentData, true);
      if (res) {
        props.node.setData({
          ...props.node.data,
          ...currentData[0],
          offerAndShippingDetails: find(props.allItemSchedules.current, [
            'itemNumberWarehouse',
            props.data.itemNumberWarehouse,
          ]),
        });
        props.api.refreshCells({
          rowNodes: [props.node],
          columns: ['quantityForecast'],
          force: true,
          suppressFlash: true,
        });
        props.gridApi.refreshCells({
          columns: ['offerDates'],
          force: true,
          suppressFlash: true,
        });
        return true;
      }
      return false;
    }
  };

  if (showCalender) {
    return (
      <CreateDateAnalyticsSelector
        dateRangeDialog={showCalender}
        offerDates={dateRange.offerDates}
        shippingDates={dateRange.shippingDates}
        updateCustomDate={saveDates}
        clearDateRangeSelector={closeAllDialogs}
        closeDateRangeSelector={closeAllDialogs}
        setLoadingDialog={props.setLoadingDialog}
        overLappingShippingDates={overLapShipping}
        daysToFinalize={props.data.daysToFinalize}
      />
    );
  }

  return (
    <>
      <TextField
        id="outlined-basic"
        variant="outlined"
        fullWidth
        className="px-itemsetup-date-field"
        inputProps={{ readOnly: true }}
        value={formatDatesByMonthDay({
          leftStartDate: selectedValue.offerStartDate,
          leftEndDate: selectedValue.offerEndDate,
          rightStartDate: selectedValue.shippingStartDate,
          rightEndDate: selectedValue.shippingEndDate,
        })}
        onClick={handleClick}
        InputProps={{ endAdornment: !open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> }}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 5, position: 'absolute', width: '100%' }}
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>
            {props.value?.map((option: any, index: number) => {
              return (
                <MenuItem
                  key={`${props.data.itemElectId}-${index}`}
                  value={option}
                  onClick={(e) => changeSelection(e, option)}>
                  {formatDatesByMonthDay({
                    leftStartDate: option.offerStartDate,
                    leftEndDate: option.offerEndDate,
                    rightStartDate: option.shippingStartDate,
                    rightEndDate: option.shippingEndDate,
                  })}
                </MenuItem>
              );
            })}
            <MenuItem
              className="grid-x"
              onClick={async () => {
                handleClose();
                setShowCalender(true);
              }}>
              <Link>Create New Dates..</Link>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default OfferShipDateComponent;
