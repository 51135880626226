import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useMemo,
  useCallback,
  Dispatch,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cloneDeep, isEmpty, keys } from 'lodash';
import { useHistory } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridOptions, RowNode } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import Pusher from 'pusher-js';

import * as preorderActions from '../../redux/actions/preorderActions';

// Custom Hooks
import useDataService from '../../hooks/useDataService';
import useAdvanceFilter from '../../hooks/useAdvanceFilter';

// Material-UI Components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Checkbox, Tooltip } from '@material-ui/core';

// Custom Components
import SearchField from '../../components/SearchField/SearchField';
import ImportReview from '../../components/ImportReview/ImportReview';
import ImportPopup from '../../components/ImportPopup/ImportPopup';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { PrimaryButton } from '../../components/AtomComponents';
import Filters from '../../components/Filters/Filters';
import ImportExportDropdown from '../../components/ImportExportDropdown/ImportExportDropdown';
import DateSelectorComponent from '../../components/PreOrders/DateSelectorComponent';
import DateRangeAnalyticsSelector from '../../components/PreOrders/DateRangeAnalyticsSelector';

// lib, api url template
import { advanceChipFilterDataState, checkNullOrUndefined } from '../../_lib/lib';
import apiToUrlMap from '../../ApiMapping';

// styles
import './PreOrders.scss';

// Helper Functions and Components
import { getTableProperties } from '../../components/PreOrders/TableProperties/TableProperties';
import {
  Recalculate,
  checkDetailRows,
  getLevelKeyValuePairs,
  transformDataToRecalculate,
  confirmDialogContent,
  // FundingNotConfirmedIndicator,
  NotificationAlertDialogContent,
  expandStatesPreservedFromLocal,
} from '../../components/PreOrders/TableCellComponents';
import { ConfirmDialog } from '../../components/Funding/ConfirmDialog';
import {
  alertMessageRecal,
  confirmDialogRecalSubLines,
  dateFormats,
  errorMessages,
  filterDataImpl,
  getDataToRecalculate,
  getDataToUpdate,
  manipulateDates,
  sanitizeData,
} from '../../components/PreOrders/Utils';

// Update Awards
import {
  updateAwardsAction,
  updateAwardsActionsList,
} from '../../components/PreOrders/UpdateAwards/UpdateAwardsActions';
import { updateAwardsReducerFn } from '../../components/PreOrders/UpdateAwards/UpdateAwardsReducer';
import {
  autoRoundOffFields,
  dateFields,
  getAggregatedNewFulfilledValidation,
  initialState,
  preprocessValue,
} from '../../components/PreOrders/UpdateAwards/UpdateAwardsHandler';

// Types
import {
  awards,
  importAwards,
  IStateHandler,
  importAwardsChanges,
  awardsKey,
  IPreOrders,
  DisableRecalculateRef,
  AllTypes,
  tabIndexStates,
  IConfirmDialogProps,
  initialConfirmDialogProps,
  IPreorderState,
  UserLocalStatesType,
  initialUserLocalStates,
  ViewLevelStatesData,
  viewByStates,
  ShowAutoRoundOffIndication,
  SelectedStatesData,
} from '../../types/IPreOrders';
import { IDateRange } from '../../types/IPricing';
import { IFilterSaveParams } from '../../types/IFilterEngine';
import { SnackBarWithActionProps } from '../../components/PreOrders/SnackBarWithActionProps';
import { AccessAlarm } from '@material-ui/icons';
import { tooltipStyleMsg } from '../../components/GridInput/GridInput';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

const PreOrders = ({ preorderState, preorderActions }: IPreOrders) => {
  const history = useHistory();

  // use data service
  const { openSnackBar, fileUpload, fetchUrl, exportData } = useDataService();

  const [inventorySyncTime, setInventorySyncTime] = useState<string>('');

  // page loading states
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  const confirmSnackBarRef = useRef<any>();
  const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(
    initialConfirmDialogProps
  );
  const toolTipMessageClass = tooltipStyleMsg();

  // Awards Data
  const preorderStateRef = useRef<IPreorderState>();
  preorderStateRef.current = preorderState;
  const { awards, awardsClosed, awardsPending } = preorderState;
  // PO-225 ( Disable Retaining Funding Requied Flag in local )
  // const [applyFundingFromLocal, setApplyFundingFromLocal] = useState<boolean>(true);
  const [tableData, setTableData] = useState<awards[]>([]);
  const [refetchFromRemote, setRefetchFromRemote] = useState<boolean>(false);
  const [performAwardsValidation, setPerformAwardsValidation] = useState<boolean>(true);

  // User Local States
  const [userLocalStates, setUserLocalStates] = useState<UserLocalStatesType>(
    initialUserLocalStates
  );
  const userLocalStatesRef = useRef<UserLocalStatesType | null>(null);
  userLocalStatesRef.current = userLocalStates;

  // Recalculate States
  const disableRecalculateRef = useRef<DisableRecalculateRef | null>(null);

  // Ag Grid and Helper States
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const gridApiRef = useRef<GridApi | null>(null);
  const pxAgGridRef = useRef<AgGridReact | null>(null);
  const [checkNodes, setCheckNodes] = useState<boolean | null>(false);

  // Tab Maintenance
  const [tabIndexState, setTabIndexState] = useState<tabIndexStates>(tabIndexStates.Open);
  const [viewByState, setViewByState] = useState<viewByStates>(viewByStates.buyer);
  const tabAndViewRef = useRef<{ tabIndexState: tabIndexStates; viewByState: viewByStates }>();
  tabAndViewRef.current = {
    tabIndexState,
    viewByState,
  };

  // Import Awards
  const [isImportingState, setIsImportingState] = useState<boolean>(false);
  const [importData, setImportData] = useState<importAwards | {}>({});
  const [importDialog, setImportDialog] = useState<boolean>(false);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const pxImportRef: any = useRef();

  const [rowDisplayedCount, setRowDisplayedCount] = useState<number>(0);

  // Update Awards
  const [updateAwardsState, dispatchUpdateAwardsAction] = useReducer(
    updateAwardsReducerFn,
    initialState
  );
  const [showAutoRoundOffIndication, setShowAutoRoundOffIndication] = useState<
    ShowAutoRoundOffIndication[] | null
  >(null);

  // Function for clearing Local States
  const clearAllLocalStates = useCallback(() => {
    preorderActions.awardsSet(null);
    preorderActions.awardsPendingSet(null);
    preorderActions.awardsClosedSet(null);
  }, [preorderActions]);

  //#region >> DateRangeSelector manager >>
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: -30 },
    }),
    endDate: manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: -1 },
    }),
  });
  const [dateRangeDialog, setDateRangeDialog] = useState<any>(false);
  const closeDateRangeSelector = () => setDateRangeDialog(false);
  const clearDateRangeSelector = () => {
    setDateRange({
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: -30 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: -1 },
      }),
    });
    setRefetchFromRemote(true);
    setDateRangeDialog(false);
  };
  const openDateRangeSelector = () => {
    setDateRangeDialog({
      attribute: 'date',
      title: 'Date Select',
    });
  };

  const updateCustomDate = ({ selectedFilters }: IFilterSaveParams) => {
    if (selectedFilters.startDate && selectedFilters.endDate) {
      setDateRange({
        startDate: manipulateDates({
          date: selectedFilters.startDate,
          formatTo: dateFormats.dateMonthIn3Letters,
          manipulate: { days: 0 },
        }),
        endDate: manipulateDates({
          date: selectedFilters.endDate,
          formatTo: dateFormats.dateMonthIn3Letters,
          manipulate: { days: 0 },
        }),
      });
    }
    setRefetchFromRemote(true);
    closeDateRangeSelector();
  };
  //#endregion << DateRangeSelector manager <<
  const settings = useMemo(() => {
    const optionalFilters = [
      'warehouse',
      'itemNumber',
      'isTakeAll',
      'category',
      'manufacturer',
      'model',
      'grade',
      'capacity',
      'color',
      'carrier',
      'screenSize',
      'protocol',
      'lockStatus',
      'buyerNumber',
    ];
    const category = ['Phones', 'Tablet', 'Wearables', 'Hearables', 'Accessories'];
    return {
      category,
      topSearchLevelForAllTenants: [],
      optionalFilters,
    };
  }, []);

  //#region >> ChipsFilter code >>
  const {
    // search terms
    tmpSearchTerm,
    setTmpSearchTerm,
    setSearchTerm,
    // filters applied state
    setFiltersApplied,
    filtersApplied,
    // advance filters
    setAdvanceFilter,
    advanceFilters,
    // reset filter function
    resetFilter,
    // items
    itemsState,
    // event
    chipsEvent,
    // count values
    filterCountState,
  } = useAdvanceFilter({
    requiredFilters:
      !!settings && !!preorderState.uniqueBuyers && !!preorderState.uniqueItems
        ? {
            // Initial State for outOfStock
            outOfStock: tabIndexState === tabIndexStates.Open ? false : true,
            buyerFilter: keys(preorderState.uniqueBuyers),
            itemFilter: keys(preorderState.uniqueItems),
          }
        : null,
    outOfStockField: 'quantityAvailable',
    advanceFilter: {
      buyerFilter: (item, filtersApplied) => filtersApplied.buyerFilter[item.buyerNumber],
      itemFilter: (item, filtersApplied) => filtersApplied.itemFilter[item.itemNumberWarehouse],
    },
    pxGridRef: pxAgGridRef,
    viewName: 'preorder',
    settings: settings,
    items: tableData,
  });

  useEffect(() => {
    gridApiRef?.current?.showLoadingOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersApplied]);

  const memorizeSelectedState = () => {
    let currentDataToRecalculate = userLocalStatesRef?.current ?? initialUserLocalStates;
    let { type, selectedStates } = currentDataToRecalculate;
    const { tabIndexState, viewByState } = tabAndViewRef.current ?? {
      tabIndexState: tabIndexStates.Open,
      viewByState: viewByStates.buyer,
    };
    currentDataToRecalculate.lastVisitedTab = tabIndexState ?? tabIndexStates.Open;
    currentDataToRecalculate.lastVisitedView = viewByState ?? viewByStates.buyer;
    if (type !== viewByState && tabIndexState === tabIndexStates.Open) {
      currentDataToRecalculate.selectedStates = transformDataToRecalculate(
        selectedStates
      ) as SelectedStatesData;
      currentDataToRecalculate.type = viewByState;
    }
    localStorage.setItem('PO-Open-Selected-State', JSON.stringify(currentDataToRecalculate));
  };

  useEffect(() => {
    if (!!inventorySyncTime) {
      return;
    }
    const getInventorySyncTime = async () => {
      try {
        const res = await fetchUrl('GET', apiToUrlMap.inventoryStats);
        const cst_time = moment.tz(res.lastSyncTs, 'America/Chicago');
        const currentTimeZone = moment.tz.guess();
        const ist_time = cst_time.tz(currentTimeZone).format('hh:mm A');
        const zone = moment().tz(currentTimeZone).format('z');
        setInventorySyncTime(`${ist_time} ${zone}`);
      } catch (error: any) {
        openSnackBar(error.message, 'error');
      }
    };
    getInventorySyncTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set Page Title and clear local states on component unmount
  useEffect(() => {
    const refreshPage = () => history.go(0);

    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' | Pre Orders';

    setDataFromLocalStorageToLocalStates();

    window.addEventListener('beforeunload', memorizeSelectedState);
    const PUSHER_APP_KEY = JSON.parse(localStorage.getItem('PxConfig') || '');
    if (PUSHER_APP_KEY?.pusherSubscriptionKey) {
      const pusher = new Pusher(PUSHER_APP_KEY?.pusherSubscriptionKey, {
        cluster: 'mt1',
      });
      const inventoryChannel = pusher.subscribe('inventory-channel');
      inventoryChannel.bind('increase-quantity-event', function (data: any) {
        confirmSnackBarRef?.current?.open(
          'New Inventory. Availability will be updated upon next recalculation or refresh page.',
          {
            label: 'REFRESH AVAILABILITY NOW',
            handler: refreshPage,
          }
        );
      });
      inventoryChannel.bind('decrease-quantity-event', function (data: any) {
        setConfirmDialogProps({
          isDialogOpen: true,
          title: 'Inventory Availability Has Decreased',
          confirmDialogContent: () => NotificationAlertDialogContent('Starting Availability'),
          confirmActionProps: {
            confirmLabel: 'UPDATE AVAILABILITY',
            confirmHandler: refreshPage,
          },
        });
      });
      const fundChannel = pusher.subscribe('fund-channel');
      fundChannel.bind('update-event', function (data: any) {
        setConfirmDialogProps({
          isDialogOpen: true,
          title: 'New Funding Information is Available',
          confirmDialogContent: () => NotificationAlertDialogContent('This funding'),
          confirmActionProps: {
            confirmLabel: 'UPDATE FUNDING',
            confirmHandler: refreshPage,
          },
        });
      });
    }

    return () => {
      window.removeEventListener('beforeunload', memorizeSelectedState);
      memorizeSelectedState();
      clearAllLocalStates();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAllLocalStates, history]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  // Update Selected State of Header CheckBox
  useEffect(() => {
    if (!!awards) {
      const disabled = {} as Partial<DisableRecalculateRef>;
      // disabled['isEditable'] = isEmpty(awards![0]?.availableFundConfirmedTimeStamp);
      disabled['totalDisabled'] =
        // isEmpty(disabled.isEditable) && isEmpty(userLocalStates.selectedStates);
        isEmpty(userLocalStates.selectedStates);
      disableRecalculateRef.current = disabled as DisableRecalculateRef;
      if (rowDisplayedCount === 0) {
        setCheckNodes(false);
        return;
      } //if no rows displayed
      let totalParentRows = 0;
      let totalParentRowsDeterminate = 0;
      for (let index = 0; index < rowDisplayedCount; index++) {
        const rowNode = gridApiRef?.current?.getDisplayedRowAtIndex(index);
        if (!rowNode?.selectable) continue;
        totalParentRows += 1;
        if (checkNullOrUndefined(rowNode?.isSelected())) totalParentRowsDeterminate += 1;
      }
      const totalParentRowsSelected = gridApiRef?.current?.getSelectedNodes()?.length ?? 0;
      if (totalParentRows === 0) return;
      if (totalParentRows === totalParentRowsSelected) {
        setCheckNodes(true);
      } else if (
        totalParentRows !== totalParentRowsSelected &&
        (totalParentRowsSelected > 0 || totalParentRowsDeterminate)
      ) {
        setCheckNodes(null);
      } else {
        setCheckNodes(false);
      }
    }
  }, [userLocalStates, awards, rowDisplayedCount]);

  // Clear Local and Redux States on Tab Switch
  useEffect(() => {
    return () => {
      const { viewByState, tabIndexState } = tabAndViewRef?.current ?? {
        tabIndexState: tabIndexStates.Open,
        viewByState: viewByStates.buyer,
      };
      const { selectedStates, type } = userLocalStatesRef?.current ?? {};
      if (tabIndexState === tabIndexStates.Open && viewByState !== type) {
        const transformedDataToRecalculate = transformDataToRecalculate(selectedStates);
        setUserLocalStates((prev: UserLocalStatesType) => ({
          ...prev,
          type: viewByState,
          selectedStates: transformedDataToRecalculate ?? {},
        }));
      }
      // Reset update data state
      dispatchUpdateAwardsAction(
        updateAwardsAction('customStateSetter', {
          ...initialState,
        })
      );
    };
  }, [tabIndexState, viewByState]);

  // Fetch Awards From Remote
  useEffect(() => {
    // Set Timeout to prevent unwanted network call
    const apiCallTimer = setTimeout(() => {
      if (tabIndexState === tabIndexStates.Open && (awards === null || refetchFromRemote)) {
        const getAwards = async () => {
          const preorderAwards = (await getPreorderAwards('open')) as awards[];
          preorderActions.awardsSet(cloneDeep(preorderAwards));
        };
        getAwards();
      }
      if (tabIndexState === tabIndexStates.Closed && (awardsClosed === null || refetchFromRemote)) {
        const getAwardsClosed = async () => {
          const preorderAwardsClosed = (await getPreorderAwards(
            'closed',
            manipulateDates({
              date: dateRange.startDate,
              formatTo: dateFormats.yearMonthDate,
              manipulate: { days: 0 },
            }),
            manipulateDates({
              date: dateRange.endDate,
              formatTo: dateFormats.yearMonthDate,
              manipulate: { days: 0 },
            })
          )) as awards[];
          preorderActions.awardsClosedSet(cloneDeep(preorderAwardsClosed));
        };
        getAwardsClosed();
      }
      if (
        tabIndexState === tabIndexStates.Pending &&
        (awardsPending === null || refetchFromRemote)
      ) {
        const getAwardsPending = async () => {
          const preorderAwardsPending = (await getPreorderAwards('pending')) as awards[];
          preorderActions.awardsPendingSet(cloneDeep(preorderAwardsPending));
        };
        getAwardsPending();
      }
      // PO-225 ( Disable Retaining Funding Requied Flag in local )
      // if (refetchFromRemote && tabIndexState === tabIndexStates.Open) setApplyFundingFromLocal(true);
      setRefetchFromRemote(false);
    }, 0);
    return () => clearTimeout(apiCallTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndexState, viewByState, awards, awardsClosed, awardsPending, preorderActions, dateRange]);

  // PO-225 ( Disable Retaining Funding Requied Flag in local )
  // useEffect(() => {
  //   if (!applyFundingFromLocal || isEmpty(awards) || isEmpty(userLocalStates.fundingRequired))
  //     return;
  //   if (tabAndViewRef.current?.tabIndexState !== tabIndexStates.Open) return;
  //   let newAwardToUpdate: any = cloneDeep(preorderStateRef?.current?.awards ?? []);
  //   const localFundingBuyers = keys(userLocalStatesRef.current?.fundingRequired ?? {});
  //   newAwardToUpdate = newAwardToUpdate.map((currentAward: awards) => {
  //     if (!localFundingBuyers.includes(currentAward.buyerNumber)) return currentAward;
  //     const isFundingRequired =
  //       userLocalStatesRef.current?.fundingRequired[currentAward.buyerNumber];
  //     return { ...currentAward, isFundingRequired };
  //   });
  //   preorderActions.updateAwardsOpen(tabIndexStates.Open, newAwardToUpdate);
  //   setApplyFundingFromLocal(false);
  // }, [awards, preorderActions, userLocalStates, applyFundingFromLocal]);

  // Prepare Unique data state for filters
  useEffect(() => {
    if (tabIndexState === tabIndexStates.Open) {
      setTableData(cloneDeep(awards) ?? []);
    }
    if (tabIndexState === tabIndexStates.Closed) {
      setTableData(cloneDeep(awardsClosed) ?? []);
    }
    if (tabIndexState === tabIndexStates.Pending) {
      setTableData(cloneDeep(awardsPending) ?? []);
    }
  }, [tabIndexState, awards, awardsClosed, awardsPending, viewByState]);

  // Recompute filter Counts for new Data on Tab Switch
  useEffect(() => {
    if (
      isEmpty(filtersApplied) ||
      isEmpty(preorderState.uniqueBuyers) ||
      isEmpty(preorderState.uniqueItems)
    )
      return;
    const filterSetTimer = setTimeout(() => {
      setFiltersApplied({
        ...filtersApplied,
        buyerFilter: preorderState.uniqueBuyers,
        itemFilter: preorderState.uniqueItems,
      });
      resetFilter();
    }, 0);
    return () => clearTimeout(filterSetTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndexState, preorderState.uniqueBuyers, preorderState.uniqueItems]);

  // Set TableData and Expand rows
  useEffect(() => {
    const filterBy = viewByState === viewByStates.buyer ? ['buyerNumber'] : ['itemNumberWarehouse'];
    if (!itemsState) return;
    if (isEmpty(gridApi)) return;

    let dataSettingTimer: NodeJS.Timeout;

    if (!!itemsState && itemsState.length === 0) {
      gridApiRef.current?.setRowData(itemsState ?? []);
      dataSettingTimer = setTimeout(() => {
        gridApiRef?.current?.showNoRowsOverlay();
        setRowDisplayedCount(0);
      }, 0);
      return;
    }

    dataSettingTimer = setTimeout(() => {
      gridApiRef.current?.showLoadingOverlay();
      gridApiRef.current?.setRowData(sanitizeData(cloneDeep(itemsState ?? []), filterBy));
      gridApiRef.current?.hideOverlay();
      if (tabIndexState === tabIndexStates.Open) selectNodes();
      expandStatesPreservedFromLocal(
        tabIndexState,
        viewByState as keyof ViewLevelStatesData,
        userLocalStatesRef.current?.expandedStates,
        gridApiRef?.current ?? undefined
      );
      setRowDisplayedCount((prev: number) => gridApiRef?.current?.getDisplayedRowCount() ?? prev);
      if (performAwardsValidation && awards && !isEmpty(awards)) {
        let isNotValid = false,
          itemNumber: string = '';
        for (let index = 0; index < awards?.length ?? 0; index++) {
          const currentAward = cloneDeep(awards[index]);
          currentAward.subGridByItem = filterDataImpl({
            data: awards,
            filterToApply: { itemNumberWarehouse: currentAward.itemNumberWarehouse },
          });
          const isNewFulfilledLessThanAvailable = getAggregatedNewFulfilledValidation(
            [],
            currentAward,
            currentAward.quantityReadyToFulfill.toString()
          );
          if (!isNewFulfilledLessThanAvailable) {
            isNotValid = true;
            itemNumber = currentAward.itemNumber;
            break;
          }
        }
        if (isNotValid) {
          let message = `${errorMessages.itemCountError} ${itemNumber}`;
          openSnackBar(message, 'error');
        }
        setPerformAwardsValidation(false);
      }
    }, 0);

    return () => clearTimeout(dataSettingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState, viewByState, tabIndexState, gridApi]);

  // Update Awards on Valid data Modification
  useEffect(() => {
    if (!updateAwardsState) return;
    const { validationStatus, valueToUpdate, lastModifiedField, stateHandler } = updateAwardsState;
    if (validationStatus === 'pending') {
      stateHandler.forEach((item: IStateHandler) => item.setError(item.message));
    }
    if (validationStatus === 'valid') {
      let awards = valueToUpdate.map((data: awards) => getDataToUpdate(data, false));
      const updateAwards = async () => {
        gridApiRef.current?.showLoadingOverlay();
        try {
          await fetchUrl('patch', apiToUrlMap.getAwards, { body: { awards } });
          if (autoRoundOffFields.includes(lastModifiedField)) {
            setTimeout(() => {
              const autoRoundOffFields: Partial<ShowAutoRoundOffIndication[]> = [];
              awards = valueToUpdate?.forEach((data: awards) => {
                const [currentAwardState] = filterDataImpl({
                  data: stateHandler,
                  filterToApply: {
                    buyerNumber: data.buyerNumber,
                    itemNumberWarehouse: data.itemNumberWarehouse,
                    colId: lastModifiedField,
                  },
                });
                if (!currentAwardState || !currentAwardState.isAutoRounded) return;
                const { buyerNumber, itemNumberWarehouse, colId } = currentAwardState;
                autoRoundOffFields.push({
                  buyerNumber,
                  itemNumberWarehouse,
                  message: `Increments of ${data.incrementSize}`,
                  colId,
                  endAdornmentType: 'roundOff',
                });
              });
              setShowAutoRoundOffIndication(autoRoundOffFields as ShowAutoRoundOffIndication[]);
            }, 1000);
          }
          dispatchUpdateAwardsAction(updateAwardsAction('customStateSetter', { ...initialState }));
          preorderActions.updateAwardsOpen(tabIndexState, awards, lastModifiedField);
          openSnackBar('Value updated successfully', 'success');
        } catch (error) {
          gridApiRef.current?.hideOverlay();
          const newStateHandler = stateHandler.map((state: IStateHandler) => ({
            ...state,
            message: dateFields.includes(state.colId) ? state.message : 'Invalid Data',
          }));
          dispatchUpdateAwardsAction(
            updateAwardsAction('customStateSetter', {
              valueToUpdate,
              lastModifiedField,
              stateHandler: newStateHandler,
              validationStatus: 'pending',
            })
          );
          openSnackBar('Unable to update value', 'error');
        }
        gridApiRef.current?.hideOverlay();
      };
      updateAwards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAwardsState]);

  useEffect(() => {
    if (isEmpty(showAutoRoundOffIndication) || !showAutoRoundOffIndication) return;
    showAutoRoundOffIndication.forEach((autoRoundOffField: any) => {
      const { colId, message, endAdornmentType } = autoRoundOffField;
      const { topLevelKeyValue, secondLevelKeyValue } = getLevelKeyValuePairs(
        tabAndViewRef?.current?.viewByState!,
        autoRoundOffField
      );
      const parentNode = gridApiRef?.current?.getRowNode(topLevelKeyValue);
      const childNode = parentNode?.detailNode?.detailGridInfo?.api?.getRowNode(
        `detail_${topLevelKeyValue}_${secondLevelKeyValue}`
      );
      if (!childNode) return;
      const { errorHandlers } = childNode?.data;
      const { error, endAdornment } = errorHandlers[colId];
      error(message);
      endAdornment(endAdornmentType);
      setTimeout(() => {
        error('');
        endAdornment('increment');
      }, 5000);
    });
    setShowAutoRoundOffIndication(null);
  }, [showAutoRoundOffIndication]);

  const setDataFromLocalStorageToLocalStates = () => {
    let userPreservedStates: any = localStorage.getItem('PO-Open-Selected-State');
    if (userPreservedStates)
      userPreservedStates = JSON.parse((userPreservedStates as string) ?? '') ?? {};
    if (isEmpty(userPreservedStates)) return;
    let {
      type,
      lastVisitedView,
      lastVisitedTab,
      selectedStates,
    } = userPreservedStates as UserLocalStatesType;
    if (type !== lastVisitedView && lastVisitedTab === tabIndexStates.Open) {
      userPreservedStates.selectedStates = transformDataToRecalculate(selectedStates);
      userPreservedStates.lastVisitedView = lastVisitedView;
    }
    setUserLocalStates(userPreservedStates);
    setTabIndexState(userPreservedStates.lastVisitedTab);
    setViewByState(userPreservedStates.lastVisitedView);
  };

  const getPreorderAwards = async (
    status: string,
    shippingStartDate?: string,
    shippingEndDate?: string
  ) => {
    try {
      gridApiRef?.current?.showLoadingOverlay();
      const params =
        status === ('open' || 'pending')
          ? { status }
          : {
              status,
              startDate: shippingStartDate,
              endDate: shippingEndDate,
            };
      const res: { awards: awards[] } = await fetchUrl('get', apiToUrlMap.getAwards, {
        params,
      });
      gridApiRef?.current?.hideOverlay();
      return res.awards;
    } catch (error) {
      gridApiRef?.current?.hideOverlay();
      console.log('error while dummy auth call', error);
    }
  };

  const handleUpload = async (file: File) => {
    try {
      const res = (await fileUpload(file, apiToUrlMap.preordersImport)) as importAwards;
      const dataToImport = cloneDeep(res);
      let newBuyerNotFound: any;
      if (dataToImport.errors['BUYER#_NOT_FOUND']) {
        newBuyerNotFound = [
          ...dataToImport.errors['BUYER#_NOT_FOUND'],
          ...dataToImport.errors.BUYER_NOT_PARTICIPATING,
        ];
      }
      delete dataToImport.errors['BUYER#_NOT_FOUND'];
      dataToImport.errors.BUYER_NOT_PARTICIPATING = newBuyerNotFound;
      console.log(res);
      setImportData(dataToImport);
      setCurrentFileName(file.name);
      setIsImportingState(true);
      closeImportDialog();
    } catch (error: any) {
      pxImportRef?.current?.setImportError(error.message);
    }
  };

  const handleSave = async (changes: importAwardsChanges) => {
    try {
      let awards = [...changes.newAwards, ...changes.updatedAwards, ...changes.deleteAwards];
      await fetchUrl('put', apiToUrlMap.preordersImportAccept, { body: { awards } });
      clearAllLocalStates();
      return true;
    } catch (error) {
      return false;
    }
  };

  const exportPOAwards = async (
    status: string,
    shippingStartDate?: string,
    shippingEndDate?: string
  ) => {
    const params =
      status === ('open' || 'pending')
        ? { status }
        : {
            status,
            startDate: shippingStartDate,
            endDate: shippingEndDate,
          };
    const currentDate = manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.yearMonthDate,
      manipulate: { days: 0 },
    });
    const monthDate = currentDate.substr(5, 2) + currentDate.substr(8, 2);
    let fileName = `exportPOAwards_${status}_${monthDate}`;
    await exportData({
      url: apiToUrlMap.exportPOAwards,
      fileName,
      additionalParams: {
        params,
      },
    });
  };

  const handleFileExport = async () => {
    try {
      setLoadingDialog(true);
      if (tabIndexState === tabIndexStates.Closed) {
        await exportPOAwards(
          'closed',
          manipulateDates({
            date: dateRange.startDate,
            formatTo: dateFormats.yearMonthDate,
            manipulate: { days: 0 },
          }),
          manipulateDates({
            date: dateRange.endDate,
            formatTo: dateFormats.yearMonthDate,
            manipulate: { days: 0 },
          })
        );
      } else {
        await exportPOAwards(tabIndexState === tabIndexStates.Pending ? 'pending' : 'open');
      }
      setLoadingDialog(false);
    } catch (error: any) {
      setLoadingDialog(false);
      openSnackBar('Invalid Date Configurations', 'error');
    }
  };

  const selectNodes = () => {
    for (let key in userLocalStates.selectedStates) {
      const masterNode: any = gridApiRef.current?.getRowNode(key);
      const subGrid = masterNode?.data.subGrid;
      if (!masterNode) continue;
      if (!isEmpty(gridApiRef.current) && masterNode) {
        masterNode.data.childIdsSelected = userLocalStates.selectedStates[key];
      }
      let selectedStateCount = userLocalStates.selectedStates[key].length;
      let filteredSelectedStateCount = 0;
      const searchBy = viewByState === viewByStates.item ? 'buyerNumber' : 'itemNumberWarehouse';
      if (subGrid?.length <= selectedStateCount) {
        subGrid?.forEach((element: any) => {
          if (userLocalStates.selectedStates[key].includes(element[searchBy]))
            filteredSelectedStateCount += 1;
        });
        if (filteredSelectedStateCount) selectedStateCount = filteredSelectedStateCount;
      }
      if (subGrid?.length === selectedStateCount && filteredSelectedStateCount > 0) {
        masterNode.data.checkingFromDetail = true;
        masterNode?.setSelected(true);
        checkDetailRows(masterNode, userLocalStates.selectedStates[key], viewByState);
        setTimeout(() => (masterNode.data.checkingFromDetail = false), 5);
      } else if (subGrid?.length <= selectedStateCount) {
        masterNode.data.checkingFromDetail = true;
        masterNode?.selectThisNode(false);
        setTimeout(() => (masterNode.data.checkingFromDetail = false), 5);
      } else {
        masterNode.data.checkingFromDetail = true;
        masterNode?.selectThisNode(undefined);
        checkDetailRows(masterNode, userLocalStates.selectedStates[key], viewByState);
        setTimeout(() => (masterNode.data.checkingFromDetail = false), 5);
      }
    }
  };

  // Set GridApi to Local State
  const onGridReady = (params: GridOptions) => {
    gridApiRef.current = params.api ?? null;
    setGridApi(params.api ?? null);
  };

  // Check Detail Rows on Detail Grid Ready
  const onDetailGridReady = (params: GridOptions) => {
    if (!userLocalStatesRef?.current?.selectedStates) return;
    const { topLevelKeyValue } = getLevelKeyValuePairs(
      viewByState,
      params?.api?.getDisplayedRowAtIndex(0)?.data
    );
    const childIdsSelected = userLocalStatesRef?.current?.selectedStates[topLevelKeyValue];
    params?.api?.forEachNode((node: RowNode) => {
      const { secondLevelKeyValue } = getLevelKeyValuePairs(viewByState, node?.data);
      if (childIdsSelected?.includes(secondLevelKeyValue)) node.setSelected(true);
      else node.setSelected(false);
    });
  };

  // PO-225 ( Disable Retaining Funding Requied Flag in local )
  // const performFundingModifications = (data: awards, newValue: string) => {
  //   let currentBuyerAwards = filterDataImpl({
  //     data: awards ?? [],
  //     filterToApply: { buyerNumber: data.buyerNumber },
  //   });
  //   currentBuyerAwards = currentBuyerAwards.map((currentAward: awards) => ({
  //     ...currentAward,
  //     isFundingRequired: newValue,
  //   }));
  //   preorderActions.updateAwardsOpen(tabIndexStates.Open, currentBuyerAwards);
  //   setUserLocalStates((prev: UserLocalStatesType) => ({
  //     ...prev,
  //     fundingRequired: {
  //       ...prev.fundingRequired,
  //       [data.buyerNumber]: newValue,
  //     },
  //   }));
  // };

  const updateHandler = async (
    params: any,
    newValue: string,
    setError: Dispatch<string>,
    setValue: Dispatch<AllTypes>
  ) => {
    let data = params.data,
      colId: awardsKey = params.column.colId;
    const { isValid, message, processedValue, changesExists } = preprocessValue(
      colId,
      newValue,
      data
    );
    // PO-225 ( Disable Retaining Funding Requied Flag in local )
    // if (colId === 'isFundingRequired') {
    //   performFundingModifications(params.data, processedValue as string);
    //   return;
    // }
    if (newValue === 'Y' && colId === 'isTakeAll') {
      if (changesExists) data.setTakeLimitDisabled(false, 'TakeLimitPercent Required');
      else data.setTakeLimitDisabled(false, '');
    }
    if (newValue === 'N' && colId === 'isTakeAll') data.setTakeLimitDisabled(true, '');
    if (!changesExists) return;
    if (!isValid) {
      dispatchUpdateAwardsAction(
        updateAwardsAction(updateAwardsActionsList.errorState, {
          data,
          colId,
          setError,
          setValue,
          message,
          processedValue,
        })
      );
      return;
    }
    dispatchUpdateAwardsAction(
      updateAwardsAction(updateAwardsActionsList[colId], {
        data,
        colId,
        setError,
        setValue,
        message,
        processedValue,
      })
    );
  };

  const {
    gridOptionsBuyerOpen,
    gridOptionsItemOpen,
    gridOptionsBuyerClosed,
    gridOptionsItemClosed,
    gridOptionsBuyerPending,
    gridOptionsItemPending,
  } = getTableProperties({
    updateHandler,
    onGridReady,
    onDetailGridReady,
    setRowDisplayedCount,
    setUserLocalStates,
  });

  const handleChange = () => {
    let updatedSelectedStates: SelectedStatesData = {};
    if (!checkNodes) {
      gridApiRef?.current?.forEachNode((node: RowNode) => {
        const { topLevelKeyValue, secondLevelKey } = getLevelKeyValuePairs(viewByState, node.data);
        node.data.checkingFromDetail = true;
        node.setSelected(true);
        const childIdsSelected = node.data.subGrid.map(
          (award: awards) => award[secondLevelKey as awardsKey]
        );
        if (node.expanded) checkDetailRows(node, childIdsSelected, viewByState);
        updatedSelectedStates[topLevelKeyValue] = childIdsSelected;
        setTimeout(() => (node.data.checkingFromDetail = false), 5);
      });
    } else {
      gridApiRef?.current?.forEachNode((node: RowNode) => {
        node.data.checkingFromDetail = true;
        node.setSelected(false);
        if (node.expanded) checkDetailRows(node, [], viewByState);
        setTimeout(() => (node.data.checkingFromDetail = false), 5);
      });
    }
    setUserLocalStates((prev: UserLocalStatesType) => ({
      ...prev,
      selectedStates: updatedSelectedStates,
    }));
  };

  const onRecalculate = async () => {
    const data: awards[] = [];
    for (let key in userLocalStates.selectedStates) {
      const childIds = userLocalStates.selectedStates[key];
      const subGrid = gridApiRef.current?.getRowNode(key)?.data?.subGrid;
      subGrid?.forEach((item: awards) => {
        const { secondLevelKeyValue } = getLevelKeyValuePairs(viewByState, item);
        if (childIds.includes(secondLevelKeyValue)) {
          const currentData = getDataToRecalculate(item);
          data.push({ ...currentData });
        }
      });
    }
    gridApiRef.current?.showLoadingOverlay();
    setConfirmDialogProps(initialConfirmDialogProps);
    try {
      const { awards } = await fetchUrl('put', apiToUrlMap.recalculateAwards, {
        body: { awards: data },
      });
      preorderActions.updateAwardsOpen(tabIndexStates.Open, awards);
      // PO-225 ( Disable Retaining Funding Requied Flag in local )
      // setApplyFundingFromLocal(true);
      openSnackBar('Values recalculated successfully', 'success');
    } catch (error: any) {
      openSnackBar('Unable to recalculate values', 'error');
    }
    gridApiRef.current?.hideOverlay();
  };

  const tabChange = (event: any, newValue: number) => {
    // setMainLoading(true);
    gridApiRef.current = null;
    setTableData([]);
    setTabIndexState(newValue);
  };

  // Import Dialog Manager
  const openImportDialog = () => setImportDialog(true);
  const closeImportDialog = () => setImportDialog(false);
  const reviewImportStateHandler = () => {
    setRefetchFromRemote(true);
    setIsImportingState(false);
    setImportData({});
    clearAllLocalStates();
  };

  // Confirm Recalculate Dialog Manager
  const openConfirmRecalculateHandler = () => {
    setConfirmDialogProps({
      isDialogOpen: true,
      title: 'Confirm Recalculate Selected',
      confirmDialogContent: () =>
        confirmDialogContent(
          userLocalStates,
          confirmDialogRecalSubLines,
          'recalculate',
          alertMessageRecal,
          viewByState
        ),
      confirmActionProps: {
        confirmLabel: 'RECALCULATE',
        confirmHandler: onRecalculate,
        rejectLabel: 'CANCEL',
        rejectHandler: closeConfrimRecalculateHandler,
      },
    });
  };
  const closeConfrimRecalculateHandler = () => setConfirmDialogProps(initialConfirmDialogProps);

  // CreateOrders Handler
  const openCreateOrders = () => {
    if (!awards || isEmpty(awards)) return;
    let isNotValid = false,
      itemNumber: string = '';
    for (let index = 0; index < awards?.length ?? 0; index++) {
      const currentAward = cloneDeep(awards[index]);
      currentAward.subGridByItem = filterDataImpl({
        data: awards,
        filterToApply: { itemNumberWarehouse: currentAward.itemNumberWarehouse },
      });
      const isNewFulfilledLessThanAvailable = getAggregatedNewFulfilledValidation(
        [],
        currentAward,
        currentAward.quantityReadyToFulfill.toString()
      );
      if (!isNewFulfilledLessThanAvailable) {
        isNotValid = true;
        itemNumber = currentAward.itemNumber;
        break;
      }
    }
    if (isNotValid) {
      let message = `${errorMessages.itemCountError} ${itemNumber}`;
      openSnackBar(message, 'error');
      return;
    }
    history.push('preorders/create');
  };

  return (
    <>
      <SnackBarWithActionProps ref={confirmSnackBarRef} />
      <ConfirmDialog
        isDialogOpen={confirmDialogProps?.isDialogOpen}
        title={confirmDialogProps?.title}
        confirmDialogContent={confirmDialogProps?.confirmDialogContent}
        confirmActionProps={confirmDialogProps?.confirmActionProps}
      />
      <LoadingDialog isDialogOpen={loadingDialog} />
      {isImportingState && (
        <ImportReview
          importData={importData}
          fileName={currentFileName!}
          keyGroup={{
            errors: 'preorderImportError',
            warnings: 'preorderImportWarning',
            changes: 'preorderImportChange',
          }}
          closeImport={reviewImportStateHandler}
          saveImport={handleSave}
          settings={settings}
          screen="preorder"
        />
      )}
      <DateRangeAnalyticsSelector
        dateRangeDialog={dateRangeDialog}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        updateCustomDate={updateCustomDate}
        clearDateRangeSelector={clearDateRangeSelector}
        closeDateRangeSelector={closeDateRangeSelector}
        type="fulfillment"
        disableDates={{
          endDate: {
            after: manipulateDates({
              date: new Date().toString(),
              manipulate: { days: -1 },
              formatTo: dateFormats.monthDateYear,
              currentFormat: dateFormats.timestamp,
            }),
          },
        }}
      />
      {!isImportingState && (
        <div className="grid-x px-preorder">
          <h2 className="cell small-5 margin-bottom-2">PO Fulfillment</h2>
          <ImportPopup
            title="Import PO Awards"
            desc=""
            ref={pxImportRef}
            open={importDialog}
            handleClose={closeImportDialog}
            handleUpload={handleUpload}
          />
          <div className="cell small-7 text-right margin-bottom-1 px-po-import-bar">
            <ImportExportDropdown
              importExportOptions={{
                importLabel: 'IMPORT PO AWARDS',
                importAction: openImportDialog,
                exportLabel: 'EXPORT PO AWARDS',
                exportAction: handleFileExport,
                actionButtonParams: {
                  import: {
                    'data-id': 'importPO',
                  },
                  export: {
                    'data-id': 'exportPO',
                  },
                },
              }}
            />
            <PrimaryButton data-id="createOrders" onClick={openCreateOrders}>
              CREATE ORDERS
            </PrimaryButton>
          </div>
          {/* {disableRecalculateRef.current?.isEditable &&
            awards &&
            awards.length > 0 &&
            tabIndexState === tabIndexStates.Open && (
              <div className="cell small-12">
                <FundingNotConfirmedIndicator />
              </div>
            )} */}
          <div className="cell small-12 margin-bottom-1">
            <SearchField
              tmpSearchTermState={tmpSearchTerm}
              setTmpSearchTermState={setTmpSearchTerm}
              setSearchTermState={setSearchTerm}
            />
          </div>
          <div className="cell small-12">
            {!!filterCountState && !!settings && filtersApplied && tableData.length > 0 ? (
              <>
                <Filters
                  requiredFilters={['buyerFilter', 'itemFilter']}
                  optionalFilters={settings.optionalFilters}
                  // Settings used to configure chips
                  chipsDataState={{
                    ...settings,
                    ...advanceChipFilterDataState(
                      settings,
                      preorderState.buyerFilterLabel,
                      preorderState.itemFilterLabel
                    ),
                  }}
                  // list of filters applied
                  filtersApplied={filtersApplied}
                  setFiltersApplied={setFiltersApplied}
                  // advanced Filters
                  advanceFilters={advanceFilters}
                  setAdvanceFilter={setAdvanceFilter}
                  // Menu (count of items per attribute)
                  filterCountState={filterCountState}
                  // Chips events
                  chipsEvent={chipsEvent}
                  // resetFilter
                  resetFilter={resetFilter}
                  isPreorder={true}
                />
              </>
            ) : null}
          </div>
          <div className="cell small-12">
            <div
              className="px-text-description"
              style={{
                display: 'inline-block',
                width: 'auto',
                lineHeight: '40px',
                marginRight: '10px',
              }}>
              View by:{' '}
            </div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="View By"
                name="viewByState"
                value={viewByState}
                onChange={(e: any) => setViewByState(e.target.value)}
                row
                style={{
                  display: 'inline-block',
                  width: 'auto',
                }}>
                <FormControlLabel
                  value="buyer"
                  control={<Radio color="primary" data-id="buyerRadio" />}
                  label="Buyer"
                />
                <FormControlLabel
                  value="item"
                  control={<Radio color="primary" data-id="itemRadio" />}
                  label="Item"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="cell small-12">
            <Tabs
              value={tabIndexState}
              onChange={tabChange}
              aria-label="simple tabs example"
              indicatorColor="secondary"
              textColor="secondary"
              color="primary"
              className="margin-top-1 px-inventory-tabs">
              <Tab label="Pending" color="primary" data-id="pendingTab" />
              <Tab label="Open" color="primary" data-id="openTab" />
              <Tab label="Closed" color="primary" data-id="closedTab" />
            </Tabs>
          </div>
          <div className="cell small-12 margin-top-3">
            {tabIndexState === tabIndexStates.Open && (
              <div className=" padding-left-20">
                <Checkbox
                  className="margin-bottom-2"
                  checked={!!checkNodes}
                  indeterminate={checkNodes === null}
                  onChange={handleChange}
                  // disabled={disableRecalculateRef.current?.isEditable ?? true}
                  color="primary"
                />
                <Recalculate
                  onRecalculate={openConfirmRecalculateHandler}
                  disabled={
                    (disableRecalculateRef.current?.totalDisabled || checkNodes === false) ?? true
                  }
                />
              </div>
            )}
            {!!inventorySyncTime && (
              <div className="padding-right-10 inventorySync" data-id="inventorySync">
                <Tooltip title="Sync happens about every two hours" classes={toolTipMessageClass}>
                  <div className="inventorySync">
                    <span className="px-text-description">
                      <AccessAlarm />
                    </span>
                    &nbsp;
                    <span>
                      <span className="px-text-description">Last Inventory Sync :</span>{' '}
                      {inventorySyncTime}
                    </span>
                  </div>
                </Tooltip>
              </div>
            )}
            {tabIndexState === tabIndexStates.Closed && (
              <DateSelectorComponent
                dateRange={dateRange}
                openDateRangeSelector={openDateRangeSelector}
              />
            )}
            {tabIndexState === tabIndexStates.Pending &&
              viewByState === viewByStates.buyer &&
              awardsPending && (
                <div className="ag-theme-alpine px-fulfillment-table px-min-width-buyer-table">
                  <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsBuyerPending} />
                </div>
              )}
            {tabIndexState === tabIndexStates.Pending &&
              viewByState === viewByStates.item &&
              awardsPending && (
                <div className="ag-theme-alpine px-fulfillment-table px-min-width-item-table ">
                  <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsItemPending} />
                </div>
              )}
            {tabIndexState === tabIndexStates.Open && viewByState === viewByStates.buyer && awards && (
              <div className="ag-theme-alpine px-fulfillment-table px-min-width-buyer-table">
                <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsBuyerOpen} />
              </div>
            )}
            {tabIndexState === tabIndexStates.Open && viewByState === viewByStates.item && awards && (
              <div className="ag-theme-alpine px-fulfillment-table px-min-width-item-table ">
                <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsItemOpen} />
              </div>
            )}
            {tabIndexState === tabIndexStates.Closed &&
              viewByState === viewByStates.buyer &&
              awardsClosed && (
                <div className="ag-theme-alpine px-fulfillment-table px-min-width-buyer-table">
                  <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsBuyerClosed} />
                </div>
              )}
            {tabIndexState === tabIndexStates.Closed &&
              viewByState === viewByStates.item &&
              awardsClosed && (
                <div className="ag-theme-alpine px-fulfillment-table px-min-width-item-table ">
                  <AgGridReact ref={pxAgGridRef} gridOptions={gridOptionsItemClosed} />
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IPreOrders) => ({
  preorderState: state.preorderState,
});

const mapDispatchToProps = (dispatch: any) => ({
  preorderActions: bindActionCreators(preorderActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreOrders);
