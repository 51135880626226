import { useErrorStatus } from '../ErrorHandler';
import { IMessageType, eMessageType } from '../types/IMessageType';
import { downloadFile, getToken, sanitize } from '../_lib/lib';
import axios, { Method } from 'axios';

const useDataService = () => {
  const {
    openSnackBar,
  }: {
    openSnackBar(message: any, type: IMessageType): any;
  } = useErrorStatus();

  const pxConfig =
    localStorage.getItem('PxConfig') ||
    `{"tenantApiName":"tenantSpecificEndpoint", "tenantApiEndPoint":"https://k6kyv5b46k.execute-api.us-east-1.amazonaws.com/dev"}`;
  const tenantEndPoint = JSON.parse(pxConfig).tenantApiEndPoint;

  // fetchUrl(getCustomerParams(buyerId, buyerData)) {}

  const fetchUrl = async (
    httpMethod: Method,
    path: string,
    additionalParams: any = {},
    manualCatchHandle: any = null
  ) => {
    try {
      const token = getToken();
      const result = await axios({
        method: httpMethod,
        url: tenantEndPoint + path,
        data: additionalParams?.body,
        ...additionalParams,
        headers: {
          Authorization: `Bearer ${token}`,
          ...additionalParams?.headers,
        },
      });
      return result.data;
    } catch (error) {
      return await handleError(error, manualCatchHandle, {
        url: path,
        method: httpMethod,
        body: additionalParams.body,
        additionalParams: additionalParams,
      });
    }
  };

  const refreshToken = async (params: any) => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '{}');
    try {
      const token = getToken();
      if (!config.business_tenant_site) throw new Error('Unauthorize error');
      const result = await axios({
        method: 'GET',
        url: config.business_tenant_site + '/jwt/refresh-token',
        // params: {
        //   jwtToken: token,
        // },
        // data: {
        //   redirectTo: document.location.href,
        //   jwtToken: token,
        // },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // set cookie
      const res = result.data;

      if (!res?.expiresOn || !res?.token) {
        return (window.location.href =
          (config?.business_tenant_site || '/') +
          `/jwt/token?redirectTo=` +
          document.location.href.split('https://')[1]);
      }

      // extacting domain from config?.business_tenant_site
      const domainParts: Array<string> = config?.business_tenant_site.split('.');
      domainParts.shift();
      const domain = domainParts.join('.');
      document.cookie = `jwtToken=${res.token};Domain=.${domain};expires=${res.expiresOn}`;
      // and redo the existing request
      return fetchUrl(params.method, params.url, {
        ...params.additionalParams,
        body: params.body,
      });
    } catch (error: any) {
      openSnackBar(error.message, eMessageType.error);
      const urlPieces = document.location.href.split('https://');
      window.location.href =
        (config?.business_tenant_site || '/') +
        `/jwt/token?redirectTo=` +
        (urlPieces[1] || urlPieces[0]);
      return;
    }
  };

  interface IFileUploadHeader {
    header: string;
    value: string;
  }

  const fileUpload = async (
    file: File,
    path: string,
    headers?: Array<IFileUploadHeader>,
    contentType?: string
  ) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        var xmlHttpRequest = new XMLHttpRequest();
        const token = getToken();
        xmlHttpRequest.open('POST', tenantEndPoint + path, true);
        xmlHttpRequest.setRequestHeader(
          'Content-Type',
          contentType
            ? contentType
            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${token}`);
        xmlHttpRequest.setRequestHeader('file-name', sanitize(file.name));
        if (headers) {
          headers.forEach((val) => {
            xmlHttpRequest.setRequestHeader(val.header, val.value);
          });
        }
        xmlHttpRequest.setRequestHeader('Accept', 'application/json');
        xmlHttpRequest.onreadystatechange = function () {
          // In local files, status is 0 upon success in Mozilla Firefox
          if (xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
            if (xmlHttpRequest.status !== 200) {
              console.log(xmlHttpRequest.response);
              reject(
                JSON.parse(
                  xmlHttpRequest.response.length > 0
                    ? xmlHttpRequest.response
                    : '{"message":"Something went wrong"}'
                )
              );
              return;
            }
            resolve(
              JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}')
            );
          }
        };
        xmlHttpRequest.send(file);
      } catch (error: any) {
        handleError(error, false);
      }
    });
  };

  interface IExportData {
    url: string;
    method?: Method;
    fileName?: string;
    body?: any;
    additionalParams?: any;
    buyerId?: any;
  }

  const exportData = async ({
    url,
    method,
    fileName,
    body,
    additionalParams,
    buyerId,
  }: IExportData) => {
    try {
      const file = await fetchUrl(method || 'get', url, {
        ...additionalParams,
        body: body || {},
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ...buyerId,
        },
        responseType: 'blob',
        response: true,
      });
      return await downloadFile(file, fileName);
    } catch (error: any) {
      console.log('error while downloading file: ', error);
      return handleError(error, false, { url, method, fileName, body, additionalParams });
    }
  };

  const handleError: any = async (error: any, manualCatchHandle: boolean, params: IExportData) => {
    const { response } = error;
    let errorMessage = response?.data?.message || error?.message || 'Internal Server Error';
    console.log(response, 'response', response?.status);

    if (
      !response ||
      response?.status === 401 ||
      response?.status === 403 ||
      response?.data?.statusCode === 401
    ) {
      // TODO: remove from saas
      // get another token
      // retry => the existing req
      return await refreshToken(params);
    }
    if (response?.data?.statusCode === 500) {
      errorMessage = 'Invalid Request';
    }
    if (!manualCatchHandle) {
      openSnackBar(errorMessage, eMessageType.error);
    }
    console.log(errorMessage);
    throw new Error(errorMessage);
  };

  return { fetchUrl, openSnackBar, fileUpload, exportData, getToken };
};

export default useDataService;
