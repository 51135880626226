import { GridOptions } from 'ag-grid-community';
import { Dispatch } from 'react';

export enum tabIndexStates {
  'Pending' = 0,
  'Open' = 1,
  'Closed' = 2,
}

export enum viewByStates {
  'buyer' = 'buyer',
  'item' = 'item',
}

export const initialExpandedStates = {
  [tabIndexStates.Open]: { item: [], buyer: [] },
  [tabIndexStates.Closed]: { item: [], buyer: [] },
  [tabIndexStates.Pending]: { item: [], buyer: [] },
};

export const initialUserLocalStates: UserLocalStatesType = {
  selectedStates: {},
  // fundingRequired: {},
  expandedStates: initialExpandedStates,
  lastVisitedTab: tabIndexStates.Open,
  lastVisitedView: viewByStates.buyer,
  type: viewByStates.buyer,
};

export interface ColumnDefinitions {
  updateHandler: (
    params: any,
    newValue: string,
    setError: Dispatch<string>,
    setValue: Dispatch<AllTypes>
  ) => void;
}

export interface TableProperties {
  updateHandler: (
    params: any,
    newValue: string,
    setError: Dispatch<string>,
    setValue: Dispatch<AllTypes>
  ) => void;
  onGridReady: (params: GridOptions) => void;
  onDetailGridReady: (params: GridOptions) => void;
  setUserLocalStates: Dispatch<UserLocalStatesType>;
  setRowDisplayedCount: Dispatch<number>;
}

export interface IPreOrders {
  preorderState: IPreorderState;
  preorderActions: IPreorderActions;
}

export interface IPreorderState {
  awards: awards[] | null;
  awardsClosed: awards[] | null;
  awardsPending: awards[] | null;
  uniqueBuyers: IUniqueFilterSpecificData<boolean> | null;
  uniqueItems: IUniqueFilterSpecificData<boolean> | null;
  buyerFilterLabel: IUniqueFilterSpecificData<string> | null;
  itemFilterLabel: IUniqueFilterSpecificData<string> | null;
}

export interface IUniqueFilterSpecificData<Value> {
  [key: string]: Value;
}

export interface IPreorderActions {
  awardsSet: (
    awards: awards[] | null
  ) => {
    type: string;
    awards: awards[] | null;
  };
  awardsClosedSet: (
    awardsClosed: awards[] | null
  ) => {
    type: string;
    awardsClosed: awards[] | null;
  };
  awardsPendingSet: (
    awardsPending: awards[] | null
  ) => {
    type: string;
    awardsPending: awards[] | null;
  };
  updateAwardsOpen: (
    status: number,
    values: any,
    field?: string
  ) => {
    type: string;
    payload: { status: number; values: any; field?: string };
  };
}

interface awards {
  availableFundConfirmedTimeStamp: string;
  awardedPrice: number;
  awardedQuantity: number;
  buyerGrantId: number;
  buyerName: string;
  buyerNumber: string;
  capacity: string;
  carrier: string;
  category: string;
  color: string;
  endingFund: number;
  grade: string;
  isFundingRequired: string;
  isTakeAll: boolean;
  itemElectId: number;
  itemNumber: string;
  itemNumberWarehouse: string;
  lockStatus: string;
  manufacturer: string;
  model: string;
  phonexItemDescription: string;
  protocol: string;
  quantityAvailable: number;
  quantityFulfilled: number;
  quantityReadyToFulfill: number;
  screenSize: string;
  shippingEndDate: string;
  shippingStartDate: string;
  startingFund: number;
  takeAllLimitPercentage: number;
  warehouse: string;
  incrementSize: number;
  childIdsSelected?: number[];
  checkStateFromMaster?: boolean;
  checkStateFromDetail?: boolean;
  setTakeLimitDisabled?: (status: boolean, message: string) => {};
  setIsTakeAllError: Dispatch<any>;
  subGrid?: awards[];
  isSubGrid?: boolean;
  subGridByItem?: awards[];
  subGridByBuyer?: awards[];
  quantityRecommendedForFulfill?: number;
  errorHandlers?: {
    [colId: string]: { error: Dispatch<string>; endAdornment: (status: string) => void };
  };
}

export type awardsKey = keyof awards;

interface updateAwards {
  itemElectId: number;
  buyerGrantId: number;
  shippingStartDate: string;
  shippingEndDate: string;
  awardedQuantity: number;
  awardedPrice: number;
  isTakeAll: boolean;
  takeAllLimitPercentage: number;
  quantityReadyToFulfill: number;
  isFundingRequired: boolean;
  itemNumberWarehouse?: string;
  buyerNumber?: string;
}

interface importAwardsResponse {
  itemNumber: string;
  warehouse: string;
  buyerNumber: string;
  awardedQuantity: number;
  awardedPrice: number;
  takeAll: string;
  takeAllLimit: number;
  shippingStartDate: string;
  shippingEndDate: string;
  rowNumber: number;
}

export interface importAwards {
  errors: importAwardsErrors;
  warnings: importAwardsWarnings;
  changes: importAwardsChanges;
}

interface importAwardsErrors {
  'BUYER#_NOT_FOUND'?: importAwardsResponse[];
  WAREHOUSE_NOT_FOUND: importAwardsResponse[];
  'ITEM#_NOT_FOUND': importAwardsResponse[];
  AWARDED_QUANTITY_NOT_FOUND: importAwardsResponse[];
  AWARDED_PRICE_NOT_FOUND: importAwardsResponse[];
  SHIPPING_END_DATE_NOT_FOUND: importAwardsResponse[];
  TAKE_ALL_LIMIT_NOT_FOUND: importAwardsResponse[];
  NEW_AWARD_DATES_OVERLAP_WITH_AN_EXISTING_AWARD: importAwardsResponse[];
  SHIPPING_START_DATE_NOT_FOUND: importAwardsResponse[];
  TAKE_ALL_NOT_FOUND: importAwardsResponse[];
  BUYER_NOT_PARTICIPATING: importAwardsResponse[];
}

interface importAwardsWarnings {}

export interface importAwardsChanges {
  newAwards: importAwardsResponse[];
  updatedAwards: importAwardsResponse[];
  deleteAwards: importAwardsResponse[];
}

export interface IInitialState {
  validationStatus: string;
  valueToUpdate: awards[];
  stateHandler: IStateHandler[];
  lastModifiedField: string;
}

export interface IStateHandler {
  setError: Dispatch<string>;
  setValue: Dispatch<AllTypes>;
  buyerNumber: string;
  itemNumberWarehouse: string;
  message: string;
  value: any;
  colId: string;
  isAutoRounded: boolean;
}

export interface UserLocalStatesType {
  selectedStates: SelectedStatesData;
  // fundingRequired: fundingRequiredData;
  expandedStates: expandedStatesData;
  lastVisitedTab: tabIndexStates;
  lastVisitedView: viewByStates;
  type: string;
}

export interface SelectedStatesData {
  [key: string]: string[];
}

export interface fundingRequiredData {
  [key: string]: string;
}

export interface expandedStatesData {
  [tabIndexStates.Open]: ViewLevelStatesData;
  [tabIndexStates.Closed]: ViewLevelStatesData;
  [tabIndexStates.Pending]: ViewLevelStatesData;
}

export interface ViewLevelStatesData {
  [viewByStates.item]: string[];
  [viewByStates.buyer]: string[];
}

export interface DisableRecalculateRef {
  isEditable: boolean;
  totalDisabled: boolean;
}

export interface ShowAutoRoundOffIndication {
  buyerNumber: string;
  itemNumberWarehouse: string;
  colId: string;
  message: string;
  endAdornmentType: 'roundOff' | 'error' | 'increment' | 'default';
}

export interface GetLevelKeyValuePairs {
  topLevelKey: string;
  topLevelKeyValue: string;
  secondLevelKey: string;
  secondLevelKeyValue: string;
}

export type AllTypes = string | boolean | number;

export interface IRecalculate {
  disabled: boolean;
  onRecalculate: () => void;
}

export interface IConfirmDialogProps {
  isDialogOpen: boolean;
  title: string;
  confirmDialogContent: () => any;
  disabled?: boolean;
  confirmActionProps: {
    confirmLabel: string;
    confirmHandler: () => void;
    rejectLabel?: string;
    rejectHandler?: () => void;
  };
  backdropHandler?: () => void;
  className?: string;
}

export const initialConfirmDialogProps = {
  isDialogOpen: false,
  title: '',
  confirmDialogContent: () => {},
  confirmActionProps: {
    confirmLabel: '',
    confirmHandler: () => {},
  },
};

export type { awards, updateAwards };
