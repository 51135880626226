const apiToUrlMap: any = {
  // TODO: subject to change
  apiName: 'tenantSpecificEndpoint',
  // user information
  getUserInfo: '/px-api-gateway/account/user-info',
  // bulk channel update
  bulkUpdate: '/px-api-gateway/inventory/items/bulk-update',
  updateQuantityChannel: '/px-api-gateway/inventory/item/update-quantity-channel',
  // inventory
  inventoryItems: '/px-api-gateway/inventory/items',
  // inventory import export
  inventoryExport: '/px-api-gateway/inventory/export',
  inventoryImport: '/px-api-gateway/inventory/import-inventory-file',
  inventoryImportAccept: '/px-api-gateway/inventory/import-inventory-file/accept',
  // buyer CRUD
  getCustomers: '/px-api-gateway/account/buyers',
  getCustomer: '/px-api-gateway/account/buyer/{buyerId}',
  createCustomer: '/px-api-gateway/account/buyer',
  updateCustomer: '/px-api-gateway/account/buyer/{buyerId}',
  // activate and deactivate customer
  activateCustomer: '/px-api-gateway/account/buyer/{buyerId}/activate',
  deactivateCustomer: '/px-api-gateway/account/buyer/{buyerId}/deactivate',
  // get my buyers
  getMyCustomer: '/px-api-gateway/account/my-buyers',
  // search customer
  getCustomerSearch: '/px-api-gateway/account/search-buyers-by-user?username={username}',
  // get customer settings
  getCustomerSettings: '/px-api-gateway/account/settings',
  // sales rep read
  getSalesRep: '/px-api-gateway/account/salesrep/{salesRepId}',
  getSalesReps: '/px-api-gateway/account/salesreps',
  // buyer import export urls
  exportCustomers: '/px-api-gateway/account/buyers/export',
  customerImport: '/px-api-gateway/account/buyers/import-file',
  customerImportAccept: '/px-api-gateway/account/buyers/import-file/accept',
  // buyer address export import urls
  exportCustomerAddresses: '/px-api-gateway/account/buyer/addresses/export',
  customerAddressImport: '/px-api-gateway/account/buyer/addresses/import-file',
  customerAddressImportAccept: '/px-api-gateway/account/buyer/addresses/import-file/accept',
  // users import export urls
  exportCustomerUsers: '/px-api-gateway/account/buyers/users/export',
  customerUserImport: '/px-api-gateway/account/buyer/users/import-file',
  customerUserImportAccept: '/px-api-gateway/account/buyer/users/import-file/accept',
  // crud customer address
  getCustomerAddresses: '/px-api-gateway/account/buyer/{buyerId}/addresses',
  createCustomerAddress: '/px-api-gateway/account/buyer/{buyerId}/address',
  updateCustomerAddress: '/px-api-gateway/account/buyer/{buyerId}/address/{addressId}',
  deleteCustomerAddress: '/px-api-gateway/account/buyer/{buyerId}/address/{addressId}',
  // customer permissions
  getShoppingChannels: '/px-api-gateway/account/buyer/{buyerId}/additional-channels',
  setShoppingChannels: '/px-api-gateway/account/buyer/{buyerId}/additional-channels',
  getPaymentMethods: '/px-api-gateway/account/buyer/{buyerId}/paymentmethods',
  setPaymentMethods: '/px-api-gateway/account/buyer/{buyerId}/paymentmethods',
  setShippingMethods: '/px-api-gateway/account/buyer/{buyerId}/shippingMethods',
  // customer documents
  getDocuments: '/px-api-gateway/account/buyer/{buyerId}/documents',
  createDocument: '/px-api-gateway/account/buyer/{buyerId}/document/upload/details',
  deleteDocument: '/px-api-gateway/account/buyer/{buyerId}/document/delete/{documentKey}',
  downloadDocument: '/px-api-gateway/account/buyer/{buyerId}/document/URL/{documentKey}',
  // users
  getUsers: '/px-api-gateway/account/buyer/{buyerId}/users',
  createUser: '/px-api-gateway/account/buyer/{buyerId}/user',
  updateUser: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}',
  resetPasswordUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/resetPassword',
  deactivateUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/deactivate',
  activateUsers: '/px-api-gateway/account/buyer/{buyerId}/user/{userId}/activate',
  // >> offer clearing apis >>
  // get all offers
  getOffers:
    '/px-api-gateway/stocklist/offerclearing/offers?startDateTime={startDateTime}&endDateTime={endDateTime}',
  // accept one offer
  acceptOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/accept',
  rejectOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/reject',
  counterOffer: '/px-api-gateway/stocklist/offerclearing/offer/{offerId}/counter',
  // bulk offer accept/reject/counter
  bulkAcceptOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkaccept',
  bulkRejectOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkreject',
  bulkCounterOffer: '/px-api-gateway/stocklist/offerclearing/offer/bulkcounter',
  // pricing apis
  // settings
  pricingSettingsGet: '/px-api-gateway/stocklist/pricing/settings',
  // get pricing
  pricingGet:
    '/px-api-gateway/stocklist/pricing/items?startDateTime={startDateTime}&endDateTime={endDateTime}',
  // update pricing
  pricingUpdate: '/px-api-gateway/stocklist/pricing/item/{itemNumberWarehouse}',
  // publish draft
  pricingPublish: '/px-api-gateway/stocklist/pricing/publish-draft',
  // reject draft
  pricingReject: '/px-api-gateway/stocklist/pricing/reject-draft',
  // submit draft for approval
  pricingApproval: '/px-api-gateway/stocklist/pricing/submit-for-approval',
  // import/export
  pricingExport: '/px-api-gateway/stocklist/pricing/exportitems',
  pricingImport: '/px-api-gateway/stocklist/pricing/import-pricing-file',
  pricingImportAccept: '/px-api-gateway/stocklist/pricing/accept-import',
  pricingImportCancel: '/px-api-gateway/stocklist/pricing/cancel-import',
  // stock list
  stockListExport: '/px-api-gateway/stocklist/export',
  stockListSettings: '/px-api-gateway/stocklist/stocklist/settings',
  // sales order management
  orderSettings: '/px-api-gateway/sales-order/settings',
  getOrders: '/px-api-gateway/tenant-sales-orders',
  orderOptions: '/px-api-gateway/tenant-sales-orders/order-options',
  getOrder: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}',
  orderCancel: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/cancel',
  orderQtyUpdate:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/item/{itemNumberWarehouse}',
  orderQtyDelete: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/remove-items',
  orderAdditionalServices:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-additional-services',
  orderShippingOptions: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-shipping',
  orderBillingAndPaymentOptions:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-billing-and-payment',
  shippingMethod: '',
  paymentStatus: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-payment-status',
  fullFillmentStatus:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/update-fulfillment-status',
  bulkOrderCancel: '/px-api-gateway/tenant-sales-orders/bulk-cancel',
  orderLineItemUpdate:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/item/{itemNumberWarehouse}',
  warehouseStocklist: '/px-api-gateway/stocklist/items/warehouse/{warehouse}',
  orderAddStocklist: '/px-api-gateway/stocklist/items/warehouses',
  orderLineItemAdd: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/add-items',
  orderRelease: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/release',
  orderEmailNotification: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/notify-buyer',
  createOrder: '/px-api-gateway/tenant-sales-orders',
  // demand data
  stocklistDemandData:
    '/px-api-gateway/stocklist/analytics/demanddata/{itemNumberWarehouse}?startDateTime={startDateTime}&endDateTime={endDateTime}',
  offersActivityData:
    '/px-api-gateway/stocklist/analytics/offeractivity/{offerId}?itemNumberWarehouse={itemNumberWarehouse}',
  // order export import urls
  exportImeis: '/px-api-gateway/tenant-sales-orders/export-imei-lists',
  exportImeiTemplate: '/px-api-gateway/tenant-sales-orders/download-imei-template',
  exportOrders: '/px-api-gateway/tenant-sales-orders/export-orders',
  exportOrdersDetail: '/px-api-gateway/tenant-sales-orders/export-order-details',
  exportOrder: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/export-order',
  exportInvoice:
    '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/invoice/{invoiceNumber}/export',
  exportOrderImeis: '/px-api-gateway/tenant-sales-order/{phonexOrderNumber}/export-imei-list',
  importImeis: '/px-api-gateway/tenant-sales-order/import-imei-file',
  discardImeiImport: '/px-api-gateway/tenant-sales-order/import-imei-file/{draftId}/discard',
  acceptImeiImport: '/px-api-gateway/tenant-sales-order/import-imei-file/{draftId}/accept',

  // Pre order
  authInfo: '/authenticate',
  getContract: '/px-api-gateway/module-contracts/tenant-contracts',
  getAwards: '/preorders/awards',
  getAwardsBuyer: '/preorders/awards/buyer',
  inventoryStats: '/preorders/awards/inventory/stats',
  preordersImport: '/preorders/awards/import',
  preordersImportAccept: '/preorders/awards',
  exportPOAwards: '/preorders/awards/export',
  preordersFundingImport: '/preorders/fundings/import',
  preordersFundingAccept: '/preorders/fundings',
  preorderFundingExport: '/preorders/fundings/export',
  preorderFundingGetFunds: '/preorders/fundings',
  preorderFundingConfirm: '/preorders/ledger/confirm',
  recalculateAwards: '/preorders/awards/calculate',
  createOrders: '/preorders/order',
  draftExportPdf: '/preorders/order/export',

  //Item Scheduling
  getItemSchedules: '/preorders/items/schedules',
  itemScheduling: '/preorders/items',
  deleteItem: '/preorders/items/{itemElectId}',
  importItems: '/preorders/items/import',
  exportItems: '/preorders/items/export',
  exportTemplate: '/preorders/items/template',
  getInsights: '/preorders/items/{itemElectId}/insights',
  getTimeSchedules: '/preorders/items/schedule-times',

  //PO Offers
  getPOoffers: '/preorders/offers',
  getSummary: '/preorders/offers/summary',
  importOffer: '/preorders/offers/import',
  exportOffer: '/preorders/offers/export',
  groupAnalytics: '/preorders/offerclearing/analytics/group-asp',
  itemsAnalytics: '/preorders/offerclearing/analytics/item-asp',

  //PO Clearing
  getPOclearing: '/preorders/offerclearing/offers?dateRange={dateRange}',
  updatePOclearing: '/preorders/offerclearing/offers',
  exportOfferClearing: '/preorders/offerclearing/export',
  getPOBuyers: '/preorders/offerclearing/buyer',
  importBuyerOffer: '/preorders/offerclearing/buyer/import',
  acceptBuyerOffer: '/preorders/offerclearing/buyer',
  exportBuyerTemplate: '/preorders/offerclearing/buyer/export',
};

// method format
export const formatString = (str: string, arg: any) => {
  return str.replace(new RegExp('{[a-z]+}', 'gi'), (match: string, index) => {
    const key = match.slice(1, match.length - 1);
    return arg[key];
  });
};

export default apiToUrlMap;
