import React from 'react';
import { Button } from '@material-ui/core';
const CreateOrdersButton = (props: any) => {
  const items = props.itemSelected > 0 ? props.itemSelected : '';
  const createOrder = () => {
    props.onCreateOrdersButton();
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={!props.disabled}
        onClick={createOrder}
        className="margin-bottom-2">
        CREATE {items} Orders
      </Button>
    </>
  );
};
export { CreateOrdersButton };
