import { Dispatch } from 'react';

import { awards, IStateHandler, updateAwards } from '../../../types/IPreOrders';

export interface Payload {
  data?: awards;
  colId?: string;
  setError?: Dispatch<string>;
  setValue?: Dispatch<boolean | string | number>;
  message?: string;
  processedValue?: string | boolean | number;
  valueToUpdate?: updateAwards[];
  stateHandler?: IStateHandler[];
  lastModifiedField?: string;
  validationStatus?: string;
}

const updateAwardsActionsList: { [key: string]: string } = {
  isFundingRequired: 'noValidationNeeded',
  awardedQuantity: 'noValidationNeeded',
  awardedPrice: 'noValidationNeeded',
  isTakeAll: 'isTakeAllValidation',
  takeAllLimitPercentage: 'isTakeAllValidation',
  shippingStartDate: 'shippingDatesValidation',
  shippingEndDate: 'shippingDatesValidation',
  quantityReadyToFulfill: 'quantityReadyToFulFillValidation',
  errorState: 'errorStateSetter',
  customState: 'customStateSetter',
};

const updateAwardsAction = (type: string, payload: Payload) => ({
  type,
  payload: { ...payload },
});

export { updateAwardsAction, updateAwardsActionsList };
