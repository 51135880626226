import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Tabs, Tab, Badge } from '@material-ui/core';
import Filters from '../../components/Filters/Filters';
import './PoClearing.scss';
import { advanceChipFilterDataState, categorizePoClearing, poClearingRules } from '../../_lib/lib';
import { IDateRange } from '../../types/IPricing';
import { differenceInDays, subDays } from 'date-fns';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import { eMessageType } from '../../types/IMessageType';
import useDataService from '../../hooks/useDataService';
import useAdvanceFilterV1 from '../../hooks/useAdvanceFilterV1';
import SearchFieldV1 from '../../components/SearchField/SearchFieldV1';
import {
  IAllBuyerData,
  IBuyers,
  IOffer,
  IOfferItem,
  IOffersAndAnalytics,
  IPoClearing,
  IPoClearingTabs,
  ItabStateCounts,
} from '../../types/IPoClearing';
import { IFilterOptions } from '../../types/IFilterEngine';
import { connect, RootStateOrAny } from 'react-redux';
import * as poClearingActions from '../../redux/actions/poClearingActions';
import PoClearingGrid from '../../components/PoClearing/PoClearingGrid';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import { bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import SectionProgress from '../../components/Progress/SectionProgress';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import ReactGA from 'react-ga';
import ImportExportDropdown from '../../components/ImportExportDropdown/ImportExportDropdown';
import BuyerDialog from '../../components/PoClearing/BuyerDialog';
import ImportPopup from '../../components/ImportPopup/ImportPopup';
import PoOffersItemImport from '../../components/PoOffers/PoOffersItemImport';
import { IImportOffers, OfferImportChanges } from '../../types/IPoOffers';

const PoClearing = ({
  buyerFilterLabel,
  inventoryMetricsForAllGroups,
  offerClearing,
  salesMetricsForAllGroups,
  uniqueBuyersList,
  poClearingActions,
  uniqueCategories,
  uniqueWarehouses,
}: IPoClearing) => {
  const history = useHistory();

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '{}');
    if (config.siteTitle) document.title = String(config.siteTitle) + ' | PO Clearing';
  }, []);

  //loading state
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  // use data service
  const { openSnackBar, fetchUrl, exportData, fileUpload } = useDataService();

  const pxAgGridRef = useRef<any>(null);

  const [tabState, setTabState] = useState<IPoClearingTabs>(IPoClearingTabs.all);

  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: subDays(new Date(), 7).toISOString(),
    endDate: new Date().toISOString(),
  });

  const [prefilteredItemsState, setPrefilteredItemsState] = useState<Array<IOfferItem>>();
  const [filterChanged, setFilterChanged] = useState<IOffersAndAnalytics>();
  const [tabStateCounts, setTabStateCounts] = useState<ItabStateCounts>({
    [IPoClearingTabs.awarded]: 0,
    [IPoClearingTabs.reofferedRequest]: 0,
    [IPoClearingTabs.offered]: 0,
  });
  const offerItemsCount = useRef<number>(0);
  const [buyerSelectDialog, setBuyerSelectDialog] = useState<boolean>(false);
  const [allBuyer, setAllBuyers] = useState<IAllBuyerData>();
  const [selectedBuyer, setSelectedBuyer] = useState<IBuyers>();
  const [selectedBuyerId, setSelectedBuyerId] = useState<string>();
  const [importDialog, setImportDialog] = useState<boolean>(false);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [importData, setImportData] = useState<IImportOffers | null>();
  const pxImportRef: any = useRef();

  const fetchOfferClearing = async () => {
    try {
      const dateDiff = differenceInDays(new Date(dateRange.endDate), new Date(dateRange.startDate));
      const poClearingItems = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.getPOclearing, { dateRange: dateDiff })
      );
      if (resetFilter) resetFilter();
      poClearingActions.poOfferClearingSet(cloneDeep(poClearingItems));
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
  };

  useEffect(() => {
    let fetchOfferClearingTimeout: NodeJS.Timeout;
    if (dateRange.startDate && dateRange.endDate) {
      // loading the offer clearing
      fetchOfferClearingTimeout = setTimeout(() => {
        fetchOfferClearing();
      }, 0);
    }
    return () => {
      clearTimeout(fetchOfferClearingTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    return () => poClearingActions.poOfferClearingSet(null);
  }, [history, poClearingActions]);

  useEffect(() => {
    if (!offerClearing) return;
    setPrefilteredItemsState(offerClearing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerClearing]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  // useEffect(() => {
  //   if (buyerFilterLabel) {
  //     const buyerFilterState: any = {};
  //     Object.keys(buyerFilterLabel).forEach((val: any) => {
  //       buyerFilterState[val] = buyerFilterLabel[val];
  //     });
  //     setBuyerFilter(buyerFilterState);
  //   }
  // }, [buyerFilterLabel]);

  const handleTabChange = (_: ChangeEvent<{}>, tabVal: IPoClearingTabs) => {
    setTabState(tabVal);
  };

  const settings = useMemo(() => {
    // const warehouseLabels = {
    //   W09: 'Huntington',
    //   CUST1: 'Yonkers',
    //   CUST2: 'Syracuse',
    //   W04: 'Buffalo',
    //   W37: 'White Plains',
    //   CUST5: 'Albany',
    //   W03: 'Mount Vernon',
    //   W06: 'Schenectady',
    //   VEN3: 'New Rochelle',
    //   W05: 'Rochester',
    //   W08: 'Babylon',
    //   W07: 'Utica',
    //   CUST6_FG: 'CUST6_FG',
    // };
    const optionalFilters = [
      'manufacturer',
      'model',
      'grade',
      'capacity',
      'color',
      'carrier',
      'protocol',
      'lockStatus',
      'ram',
      'bandMaterial',
      'casingMaterial',
      'casingSize',
      'cpuModel',
    ];
    return {
      // warehouseLabels,
      topSearchLevelForAllTenants: [],
      optionalFilters,
    };
  }, []);

  const filterByBuyers = (offer: IOffer, buyerFilter: IFilterOptions) => {
    if (!buyerFilter) return true;
    if (!offer.buyerId) return false;
    return buyerFilter[offer.buyerId];
  };

  const filterOfferLevelItems = (items: IOfferItem[], curTab: string) => {
    const filteredData: IOfferItem[] = [];
    items.forEach((val: IOfferItem) => {
      const curOffers: IOffer[] = [];
      val.offers.forEach((offerVal: IOffer) => {
        const isTabApplied = poClearingRules(curTab, offerVal.status);
        const isBuyerFiltered = filterByBuyers(offerVal, filtersApplied?.buyerFilter);
        if (isTabApplied && isBuyerFiltered) curOffers.push(offerVal);
      });
      if (curOffers.length > 0) {
        filteredData.push({
          ...val,
          offers: curOffers,
        });
      }
    });

    return filteredData;
  };

  //#endregion << filter code <<
  //#region >> filter code >>
  const {
    searchTerm,
    startSearch,
    // filters applied state
    setFiltersApplied,
    filtersApplied,
    // advance filters
    setAdvanceFilter,
    advanceFilters,
    // reset filter function
    resetFilter,
    // items
    itemsState,
    // event
    chipsEvent,
    // count values
    filterCountState,
  } = useAdvanceFilterV1({
    requiredFilters:
      uniqueBuyersList && buyerFilterLabel && uniqueCategories && uniqueWarehouses
        ? {
            category: [...uniqueCategories],
            buyerFilter: Object.keys(uniqueBuyersList),
            warehouse: [...uniqueWarehouses],
          }
        : null,
    advanceFilter: {
      buyerFilter: (item, filtersApplied) => {
        if (Array.isArray(item.offers)) {
          for (let offerIndex in item.offers) {
            if (filtersApplied.buyerFilter[item.offers[offerIndex].buyerId]) return true;
          }
        }
        return false;
      },
    },
    outOfStockField: 'quantityAvailable',
    pxGridRef: pxAgGridRef,
    viewName: 'poClearing',
    settings: settings,
    items: prefilteredItemsState,
  });
  //#endregion >> Chip Filter

  useEffect(() => {
    if (
      isEmpty(filtersApplied) ||
      isEmpty(uniqueBuyersList) ||
      isEmpty(uniqueCategories) ||
      isEmpty(uniqueWarehouses)
    )
      return;
    const filterSetTimer = setTimeout(() => {
      setFiltersApplied({
        ...filtersApplied,
        buyerFilter: uniqueBuyersList,
        category: uniqueCategories,
        warehouse: uniqueWarehouses,
      });
      resetFilter();
    }, 0);
    return () => clearTimeout(filterSetTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueBuyersList, uniqueCategories, uniqueWarehouses]);

  //#region >> TAB Stats manager >>
  useEffect(() => {
    if (itemsState) {
      const stats: ItabStateCounts = {
        [IPoClearingTabs.awarded]: 0,
        [IPoClearingTabs.reofferedRequest]: 0,
        [IPoClearingTabs.offered]: 0,
      };
      itemsState.forEach((val: IOfferItem) => {
        if (val.offers) {
          val.offers.forEach((offer: IOffer) => {
            const isBuyerSatified = filterByBuyers(offer, filtersApplied?.buyerFilter);
            if (!isBuyerSatified) return;
            const [itemState] = categorizePoClearing(offer);
            stats[itemState] += 1;
          });
        }
      });
      setTabStateCounts(stats);
      offerItemsCount.current = stats[IPoClearingTabs.offered];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState]);

  useEffect(() => {
    let dataSettingTimer: NodeJS.Timeout;
    if (itemsState?.length >= 0 && tabState) {
      if (pxAgGridRef?.current) pxAgGridRef.current.showLoadingOverlay();
      dataSettingTimer = setTimeout(() => {
        const filteredData = filterOfferLevelItems(itemsState, tabState);
        if (pxAgGridRef.current) {
          pxAgGridRef.current.setBulkUpdateCount0();
        }
        setFilterChanged({
          items: filteredData,
          inventoryMetricsForAllGroups: inventoryMetricsForAllGroups,
          salesMetricsForAllGroups: salesMetricsForAllGroups,
        });
      }, 500);
    }
    return () => clearTimeout(dataSettingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState?.length, filtersApplied?.buyerFilter, tabState]);

  const exportOfferSheet = async () => {
    setLoadingDialog(true);
    try {
      let today = new Date();
      let fileName = `PO Offer Clearing Sheet Export-${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate()}.xlsx`;
      await exportData({
        url: apiToUrlMap.exportOfferClearing,
        fileName,
      });
      openSnackBar('File Exported', eMessageType.success);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  const exportTemplate = async () => {
    setLoadingDialog(true);
    try {
      let today = new Date();
      let fileName = `PO Offers EXPORT-${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate()}.xlsx`;
      await exportData({
        url: apiToUrlMap.exportBuyerTemplate,
        fileName,
        buyerId: {
          buyer_id: selectedBuyerId,
        },
      });
      openSnackBar('File Exported', eMessageType.success);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  const openBuyerSelectDialog = async () => {
    setLoadingDialog(true);
    if (!allBuyer) {
      const res = await fetchUrl('GET', apiToUrlMap.getPOBuyers);
      const sortedBuyer = res.buyers.sort((currentValue: any, nextValue: any) => {
        return currentValue.buyerName.localeCompare(nextValue.buyerName);
      });
      setAllBuyers(sortedBuyer);
    }
    setBuyerSelectDialog(true);
    setLoadingDialog(false);
  };

  const closeBuyerDialog = () => {
    setBuyerSelectDialog(false);
  };

  const importBuyerOffers = (buyerId: string) => {
    if (isArray(allBuyer)) {
      const buyerDetails = allBuyer.find((val: any) => val.buyerId === parseInt(buyerId));
      setSelectedBuyer(buyerDetails);
    }
    setSelectedBuyerId(buyerId);
    setBuyerSelectDialog(false);
    setImportDialog(true);
  };
  const closeImportDialog = () => setImportDialog(false);

  const reviewImportStateHandler = () => setImportData(null);

  const handleUpload = async (file: File) => {
    try {
      const res = await fileUpload(file, apiToUrlMap.importBuyerOffer, [
        { header: 'buyer_id', value: selectedBuyerId ?? '' },
      ]);
      const dataToImport: any = cloneDeep(res);
      setImportData(dataToImport);
      setCurrentFileName(dataToImport.fileName);
      closeImportDialog();
    } catch (error: any) {
      pxImportRef?.current?.setImportError(error.message);
    }
  };

  const handleSave = async (changes: OfferImportChanges) => {
    try {
      const offers = [...changes.newOffers, ...changes.updatedOffers];
      await fetchUrl('PUT', apiToUrlMap.acceptBuyerOffer, {
        body: { offers },
        headers: { buyer_id: selectedBuyerId },
      });
      poClearingActions.poOfferClearingSet({
        inventoryMetricsForAllGroups: null,
        items: [],
        salesMetricsForAllGroups: null,
      });
      await fetchOfferClearing();
      return true;
    } catch (error) {
      return false;
    }
  };

  const shippingWindow = async (itemElectId: number) => {
    try {
      const res = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.getInsights, {
          itemElectId: itemElectId,
        })
      );
      return res;
    } catch (error) {
      openSnackBar('Something has gone wrong.Try again', eMessageType.error);
      return;
    }
  };

  const updateOffer = async (offer: IOffer[], refetchFromRemote?: boolean) => {
    if (refetchFromRemote) pxAgGridRef.current?.showLoadingOverlay();
    try {
      // const res = offer;
      const res = await fetchUrl('PATCH', apiToUrlMap.updatePOclearing, {
        body: { offers: offer },
      });
      if (refetchFromRemote) {
        poClearingActions.poOfferClearingSet({
          inventoryMetricsForAllGroups: null,
          items: [],
          salesMetricsForAllGroups: null,
        });
        await fetchOfferClearing();
      } else poClearingActions.poOfferClearingUpdate(cloneDeep(offer));
      if (refetchFromRemote) pxAgGridRef.current?.hideLoadingOverlay();
      openSnackBar('Updated successfully', eMessageType.success);
      return res;
    } catch (error: any) {
      if (error.message.includes('available forecast')) {
        openSnackBar(error.message, eMessageType.error);
        pxAgGridRef.current?.hideLoadingOverlay();
        return error.message;
      }
      if (error.message.toUpperCase().includes('FINALIZING')) {
        openSnackBar(error.message, eMessageType.error);
        pxAgGridRef.current?.hideLoadingOverlay();
        return;
      }
      openSnackBar('Something went wrong. Try again.', eMessageType.error);
      pxAgGridRef.current?.hideLoadingOverlay();
    }
  };

  if (importData) {
    return (
      <PoOffersItemImport
        importData={importData}
        fileName={currentFileName!}
        keyGroup={{
          errors: 'poOfferItemError',
          changes: 'poOfferItemChange',
          warnings: 'poOfferItemWarning',
        }}
        closeImport={reviewImportStateHandler}
        saveImport={handleSave}
        screen="poOfferChanges"
      />
    );
  }

  return (
    <>
      <div className="px-v-PoClearing">
        <main className="grid-x">
          <div className="cell small-12">
            <h2>PO Offer Clearing</h2>
          </div>
          <div className="cell small-8 margin-bottom-2">
            <SearchFieldV1
              startSearch={startSearch}
              view="itemScheduling"
              searchTerm={searchTerm.searchText}
            />
          </div>
          <LoadingDialog isDialogOpen={loadingDialog} />
          {buyerSelectDialog && allBuyer && (
            <BuyerDialog
              title="Import Buyer Offers/Select a Buyer"
              open={buyerSelectDialog}
              handleClose={closeBuyerDialog}
              buyerFilterLabel={allBuyer}
              importBuyerOffers={importBuyerOffers}
            />
          )}
          {importDialog && allBuyer && selectedBuyerId && (
            <ImportPopup
              title="Import Offers"
              desc={
                <>
                  <p>
                    <span className="px-text-description">Buyer: </span>
                    <span>
                      {selectedBuyer?.buyerName}&nbsp;-&nbsp;
                      {selectedBuyer?.buyerNumber}
                    </span>
                  </p>
                  You can use the Buyer's offer sheet as a template file for import:
                  <br />
                  <p className="px-color-link pointer-mouse" onClick={exportTemplate}>
                    Download Buyer's Offer Sheet
                  </p>
                </>
              }
              ref={pxImportRef}
              open={importDialog}
              handleClose={closeImportDialog}
              handleUpload={handleUpload}
              customError="File format must be .XLSX"
            />
          )}
          <div className="cell small-4">
            <ImportExportDropdown
              importExportOptions={{
                importLabel: 'IMPORT BUYER PO OFFERS',
                importAction: openBuyerSelectDialog,
                exportLabel: 'EXPORT PO OFFERS',
                exportAction: exportOfferSheet,
                actionButtonParams: {
                  import: {
                    'data-id': 'importBuyerPOOffers',
                  },
                  export: {
                    'data-id': 'exportPOOffers',
                  },
                },
              }}
            />
            {/* <TertiaryButton className="inline-block " onClick={exportOfferSheet}>
              <GetAppIcon />
              &nbsp; EXPORT PO OFFERS
            </TertiaryButton> */}
          </div>
          {prefilteredItemsState && filterCountState && settings && filtersApplied && (
            <>
              <div className="cell small-8">
                <Filters
                  requiredFilters={['buyerFilter', 'category', 'warehouse']}
                  optionalFilters={settings.optionalFilters}
                  // Settings used to configure chips
                  chipsDataState={{
                    ...settings,
                    ...advanceChipFilterDataState(settings, buyerFilterLabel),
                  }}
                  // list of filters applied
                  filtersApplied={filtersApplied}
                  setFiltersApplied={setFiltersApplied}
                  // advanced Filters
                  advanceFilters={advanceFilters}
                  setAdvanceFilter={setAdvanceFilter}
                  // Menu (count of items per attribute)
                  filterCountState={filterCountState}
                  // Chips events
                  chipsEvent={chipsEvent}
                  // resetFilter
                  resetFilter={resetFilter}
                  excludeOutOfStock={true}
                />
              </div>
              <div className="cell small-12">
                <Tabs
                  value={tabState}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={handleTabChange}
                  className="margin-top-1 px-inventory-tabs"
                  aria-label="inventory tab change">
                  <Tab
                    className="px-po-awards-tab px-bold-fonts"
                    label={
                      <Badge
                        badgeContent={''}
                        style={{
                          padding: '4px',
                        }}
                        max={Infinity}
                        invisible={false}>
                        ALL
                      </Badge>
                    }
                    value={IPoClearingTabs.all}
                    key={IPoClearingTabs.all}
                  />
                  <Tab
                    className="px-badge-status px-bold-fonts"
                    label={
                      <Badge
                        badgeContent={tabStateCounts[IPoClearingTabs.offered]}
                        style={{
                          padding: '4px',
                        }}
                        max={Infinity}
                        invisible={false}>
                        OFFERED
                      </Badge>
                    }
                    value={IPoClearingTabs.offered}
                    key={IPoClearingTabs.offered}
                  />
                  <Tab
                    className="px-badge-warning px-bold-fonts"
                    label={
                      <Badge
                        badgeContent={tabStateCounts[IPoClearingTabs.reofferedRequest]}
                        style={{
                          padding: '4px',
                        }}
                        max={Infinity}
                        invisible={false}>
                        RE-OFFER REQUESTS
                      </Badge>
                    }
                    value={IPoClearingTabs.reofferedRequest}
                    key={IPoClearingTabs.reofferedRequest}
                  />
                  <Tab
                    className="px-badge-ok px-bold-fonts"
                    label={
                      <Badge
                        badgeContent={tabStateCounts[IPoClearingTabs.awarded]}
                        style={{
                          padding: '4px',
                        }}
                        max={Infinity}
                        invisible={false}>
                        AWARDED
                      </Badge>
                    }
                    value={IPoClearingTabs.awarded}
                    key={IPoClearingTabs.awarded}
                  />
                </Tabs>
              </div>
            </>
          )}
        </main>
        {filterChanged && settings ? (
          <PoClearingGrid
            ref={pxAgGridRef}
            dateRangeState={{ dateRange, setDateRange }}
            fetchOfferClearing={fetchOfferClearing}
            currentTab={tabState}
            offeredTabState={offerItemsCount}
            itemsState={filterChanged}
            getShippingWindow={shippingWindow}
            updateOffer={updateOffer}
            setLoadingDialog={setLoadingDialog}
          />
        ) : (
          <SectionProgress />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state: RootStateOrAny) {
  return {
    uniqueBuyersList: state.poClearingState?.uniqueBuyers,
    buyerFilterLabel: state.poClearingState?.buyerFilterLabel,
    offerClearing: state.poClearingState.offersList,
    inventoryMetricsForAllGroups: state.poClearingState.inventoryMetricsForAllGroups,
    salesMetricsForAllGroups: state.poClearingState.salesMetricsForAllGroups,
    uniqueCategories: state.poClearingState.uniqueCategories,
    uniqueWarehouses: state.poClearingState.uniqueWarehouses,
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    poClearingActions: bindActionCreators(poClearingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PoClearing);
