import React, { useState } from 'react';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { Box, Button, Chip, ClickAwayListener, IconButton, Popper } from '@material-ui/core';
import { awaitingRule, formatDateByMonthDay } from '../PreOrders/Utils';
import CancelIcon from '@material-ui/icons/Cancel';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import { poOffersStatusChip, poOffersStatusLabelMap } from '../../types/enumToLabelsMap';
import { dataFormatting } from '../../_lib/lib';
import { IPooffersItems } from '../../types/IPoOffers';

interface IAwaitingItem {
  item: IPooffersItems;
  currentTab: string;
  deleteItem: (item: IPooffersItems, offerId: number[]) => {};
}

const AwardsAndAwaitingItem = (props: IAwaitingItem) => {
  const { item, currentTab, deleteItem } = props;
  //popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    itemDescription,
    itemNumber,
    // offerStartDate,
    // offerEndDate,
    offerQuantity,
    offerPrice,
    isTakeAll,
    offerStatus,
    awardedPrice,
    awardedQuantity,
    shippingStartDate,
    shippingEndDate,
    itemStatus,
  } = item;

  let isFinalized: boolean = itemStatus === 'FINALIZING' ? true : false;

  const handleClick = (event: any) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  let chipClasses = `${poOffersStatusChip[offerStatus]} px-filter-on`;
  if (awaitingRule(currentTab)) {
    if (isFinalized) chipClasses = `${poOffersStatusChip[offerStatus]} px-filter-off`;
  }

  return (
    <Box className="cell small-12 px-po-card-body hover-bg">
      <div className="grid-x">
        <div className="cell small-6 margin-bottom-1 px-po-offers-card-desc">
          <h6 className="bold margin-bottom-1">{itemDescription}</h6>
          <span>
            Item #: {itemNumber}
            {awaitingRule(currentTab) && isFinalized ? (
              <span className=" padding-left-1 faded_1">
                <PanToolOutlinedIcon className="vertical-middle px-margin-right-5" />
                {'Current Offer Stands'}
              </span>
            ) : (
              <></>
            )}
          </span>
          {/* {currentTab === IOffersTab.awarded ? ( */}
          <div className="faded_1">
            <LocalShippingOutlinedIcon className="vertical-middle px-margin-right-5" />
            {formatDateByMonthDay(shippingStartDate, shippingEndDate) || '- -'}
          </div>
          {/* ) : (
            <></>
          )} */}
        </div>
        <div className="grid-x cell small-6">
          <div className="cell small-4 px-po-offers-card-data">
            <span className="faded_1">Status</span>
            <Chip
              label={poOffersStatusLabelMap[offerStatus]}
              className={chipClasses}
              size="small"
            />
          </div>
          <div className="cell small-3 px-po-offers-card-data">
            <span className="faded_1 margin-bottom-1">Quantity</span>
            <span>{awaitingRule(currentTab) ? offerQuantity : awardedQuantity} </span>
          </div>
          <div className="cell small-2 px-po-offers-card-data">
            <span className="faded_1 margin-bottom-1">Price</span>
            {/* <span>${offerPrice}</span> */}
            <span>
              {awaitingRule(currentTab)
                ? dataFormatting('currency', offerPrice)
                : dataFormatting('currency', awardedPrice)}
            </span>
          </div>
          <div className="cell small-2 px-po-offers-card-data">
            <span className="faded_1 margin-bottom-1">Take All</span>
            <span>{isTakeAll === 1 ? 'YES' : 'NO'}</span>
          </div>
          <div className={`grid-x cell auto text-center align-center margin-top-2`}>
            {awaitingRule(currentTab) && !isFinalized && (
              <div>
                <IconButton className={'padding-0'} aria-describedby={id} onClick={handleClick}>
                  <CancelIcon color="primary" />
                </IconButton>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  style={{ zIndex: 5, position: 'absolute' }}
                  disablePortal={true}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                  }}>
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <div>
                      <Button
                        color="primary"
                        className="padding-1"
                        onClick={() => {
                          deleteItem(item, [item.offerId]);
                          setAnchorEl(null);
                        }}>
                        Delete
                      </Button>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default AwardsAndAwaitingItem;
