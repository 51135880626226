import React, { Dispatch, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  ColDef,
  ColGroupDef,
  ColSpanParams,
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  IItems,
  IItemSchedulingTab,
  ISelectedRowsState,
  tabIndexStates,
} from '../../types/IItemSetup';
import { LoadingComponent } from '../PreOrders/TableCellComponents';
import OfferShipDateComponent from './OfferShipDateComponent';
import { formatDateByMonthDay, orderOfItemSchedulingTabs } from '../PreOrders/Utils';
import GridInput from '../GridInput/GridInput';
import ShippingWindow from './ShippingWindow';
import { itemSchedulingDraftRule } from '../../_lib/lib';
import { preprocessValue } from '../../components/PreOrders/UpdateAwards/UpdateAwardsHandler';
import { AllTypes, awardsKey } from '../../types/IPreOrders';
import { find, isEqual, map, max } from 'lodash';
import { remove } from 'lodash';
import DeleteItem from './DeleteItem';

interface IItemSetupGrid {
  items: IItems[];
  setSelectedRowsState: React.Dispatch<React.SetStateAction<ISelectedRowsState>>;
  tabIndexState: any;
  selectedNodeIds: any;
  updateItem: any;
  deleteItem: any;
  setLoadingDialog: any;
  getShippingWindow: any;
  allItemSchedules: any;
  offeringTimeRange: any;
}

const ItemSetupGrid = forwardRef(
  (
    {
      items,
      setSelectedRowsState,
      tabIndexState,
      updateItem,
      deleteItem,
      setLoadingDialog,
      selectedNodeIds,
      getShippingWindow,
      allItemSchedules,
      offeringTimeRange,
    }: IItemSetupGrid,
    ref: any
  ) => {
    const [gridApi, setGridApi] = useState<GridApi>();

    useImperativeHandle(ref, () => {
      return {
        showLoadingOverlay: () => {
          if (gridApi) {
            gridApi.showLoadingOverlay();
          }
        },
        hideLoadingOverlay: () => {
          if (gridApi) {
            gridApi.hideOverlay();
          }
        },
        gridApi,
      };
    });

    useEffect(() => {
      if (!gridApi || !items) return;
      gridApi.setRowData(items);
      gridApi.hideOverlay();
      let totalCount = 0;
      gridApi?.forEachNode((rowNode: RowNode) => {
        if (!String(rowNode.id).startsWith('row-group') && rowNode.selectable) totalCount += 1;
      });
      setSelectedRowsState((prev: ISelectedRowsState) => ({
        ...prev,
        totalCount,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, gridApi]);

    //#region >> grid col def and options >>
    const onGridReady = (params: GridOptions) => {
      if (params.api) {
        setGridApi(params.api);
        params.api.showLoadingOverlay();
      }
    };

    const updateHandler = async (
      params: any,
      newValue: string,
      setError: Dispatch<string>,
      setValue: Dispatch<AllTypes>,
      endAdornmentHandler: any
    ) => {
      let data = params.data,
        colId: awardsKey = params.column.colId;
      if (isNaN(+newValue)) {
        setValue(params.data.quantityForecast);
        setError('Value must be numeric');
        endAdornmentHandler('error');
        return;
      }
      const { processedValue } = preprocessValue(colId, newValue, data);
      const value: number = +processedValue;
      let newValueWithTakePercent;
      let currentData;
      if (value === data.quantityForecast) {
        setValue(value);
        return;
      }
      if (value <= 0) {
        setValue(params.data.quantityForecast);
        setError('Value must be > 0');
        endAdornmentHandler('error');
        return;
      }
      if (newValue.toString().includes('.')) {
        setError('Value should not be decimal');
        endAdornmentHandler('error');
        setValue(params.data.quantityForecast);
        return;
      }
      if (isNaN(value)) {
        setValue(params.data.quantityForecast);
        setError('Value must be numeric');
        endAdornmentHandler('error');
        return;
      }
      if (data.incrementSize > 1) {
        if (value % params.data.incrementSize === 0) {
          setValue(value);
          endAdornmentHandler('increment');
        } else {
          newValueWithTakePercent = Math.floor(value / data.incrementSize) * data.incrementSize;
          setValue(newValueWithTakePercent);
          endAdornmentHandler('roundOff');
        }
      }
      currentData = [
        {
          itemElectId: data.itemElectId,
          itemNumber: data.itemNumber,
          itemNumberWarehouse: data.itemNumberWarehouse,
          warehouse: data.warehouse,
          quantityForecast: newValueWithTakePercent === undefined ? value : newValueWithTakePercent,
          offerStartDate: data.offerStartDate,
          offerEndDate: data.offerEndDate,
          shippingStartDate: data.shippingStartDate,
          shippingEndDate: data.shippingEndDate,
          status: data.status,
        },
      ];
      const res = await updateItem(currentData);
      if (res) {
        params.node.setData({
          ...params.node.data,
          ...currentData[0],
        });
      } else {
        setValue(params.data.quantityForecast);
        gridApi?.hideOverlay();
      }
    };

    useEffect(() => {
      if (items && gridApi) {
        const columnDefs: Array<ColDef | ColGroupDef> = [
          {
            field: 'groupName',
            checkboxSelection: (params: any) => {
              return itemSchedulingDraftRule(params.data);
            },
            sort: 'asc',
            sortIndex: 0,
            rowGroup: true,
            showRowGroup: true,
            sortable: false,
            hide: true,
            maxWidth: 200,
          },
          {
            field: 'itemNumber',
            hide: true,
            maxWidth: 10,
            sortable: true,
            sort: 'desc',
            sortIndex: 1,
            comparator: (a: string, b: string) => {
              if (a === b) return 0;
              else return a < b ? 1 : -1;
            },
          },
          {
            headerName: '',
            field: 'shippingWindow',
            width: 50,
            minWidth: 50,
            sortable: false,
            maxWidth: 50,
            cellRendererParams: (params: ICellRendererParams) => ({
              getShippingWindow,
              params,
            }),
            cellRendererSelector: (params: ICellRendererParams) => {
              const isItemInShipping = params.data?.status === 'SHIPPING';
              if (!isItemInShipping) return { component: '' };
              return { component: 'shippingWindow' };
            },
          },
          {
            headerName: 'Status',
            field: 'status',
            minWidth: 150,
            maxWidth: 150,
            sort: 'asc',
            sortIndex: 2,
            comparator: (a, b) => {
              const indexA = orderOfItemSchedulingTabs.indexOf(a);
              const indexB = orderOfItemSchedulingTabs.indexOf(b);
              if (indexA === indexB) return 0;
              return indexA > indexB ? 1 : -1;
            },
            cellRendererFramework: (params: ICellRendererParams) => {
              if (!params.data || params.node.rowPinned === 'top') return '';

              return (
                <>
                  <span
                    className={`inline-block text-center px-itemsetup-${(params.data
                      .status as string).toLowerCase()}`}>
                    {params.data.status[0] + params.data.status.slice(1).toLowerCase()}
                  </span>
                </>
              );
            },
          },
          {
            headerName: 'Quantity Forecast',
            field: 'quantityForecast',
            colId: 'quantityForecast',
            minWidth: 150,
            maxWidth: 150,
            sortable: false,
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.node.allChildrenCount) return '';
              if (
                params.data.status === IItemSchedulingTab.draft &&
                (params.data.quantityForecast === 0 || params.data.quantityForecast === null)
              ) {
                return 0;
              } else {
                return params.data?.quantityForecast ?? '--';
              }
            },
            cellRendererParams: (params: any) => ({
              updateHandler,
            }),
            cellRendererSelector: (params: ICellRendererParams) => {
              if (!params.data) return { component: '' };
              const canEdit = params.data.status === 'DRAFT';
              if (!canEdit) return { component: '' };
              return { component: 'gridInput' };
            },
          },
          {
            headerName: `Offer Dates`,
            colId: 'offerDates',
            minWidth: 120,
            maxWidth: 120,
            sortable: false,
            colSpan: (params: ColSpanParams) => {
              if (!params.data) return 1;
              if (params.data.status === IItemSchedulingTab.draft) return 2;
              else return 1;
            },
            cellRendererParams: (params: any) => ({
              params,
              updateItem,
              setLoadingDialog,
              allItemSchedules,
              gridApi,
              offeringTimeRange,
            }),
            cellRendererSelector: (params: ICellRendererParams) => {
              if (!params.data) return { component: '' };
              const canEdit = params.data.status === IItemSchedulingTab.draft;
              if (canEdit) return { component: 'offerShipDateComponent' };
              return { component: '' };
            },
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data) return [];
              const {
                offerStartDate,
                offerEndDate,
                shippingStartDate,
                shippingEndDate,
              } = params.data;
              const options: any = [];
              if (params.data.status !== IItemSchedulingTab.draft) {
                const formatOnlyOfferDates = formatDateByMonthDay(offerStartDate, offerEndDate);
                return !formatOnlyOfferDates ? '--' : formatOnlyOfferDates;
              }
              const formattedDate = {
                offerStartDate,
                offerEndDate,
                shippingStartDate,
                shippingEndDate,
              };
              options.push(formattedDate);
              const dataForDropDown: any = find(allItemSchedules.current, [
                'itemNumberWarehouse',
                params.data.itemNumberWarehouse,
              ]);
              if (dataForDropDown) {
                dataForDropDown.nonOverlappingSchedules.forEach((itemSchedule: any) => {
                  const formattedDateOption = {
                    offerStartDate: itemSchedule.offerStartDate,
                    offerEndDate: itemSchedule.offerEndDate,
                    shippingStartDate: itemSchedule.shippingStartDate,
                    shippingEndDate: itemSchedule.shippingEndDate,
                  };
                  if (!isEqual(formattedDate, formattedDateOption))
                    options.push(formattedDateOption);
                });
              }
              return options;
            },
          },
          {
            headerName: `Shipping Dates`,
            minWidth: 150,
            maxWidth: 150,
            sortable: false,
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data) return [];
              const { shippingStartDate, shippingEndDate } = params.data;
              const formatOnlyOfferDates = formatDateByMonthDay(shippingStartDate, shippingEndDate);
              return !formatOnlyOfferDates ? '--' : formatOnlyOfferDates;
            },
          },
          {
            headerName: '',
            sortable: false,
            cellRendererFramework: DeleteItem,
            cellRendererParams: (params: ICellRendererParams) => {
              return {
                params,
                deleteItem,
              };
            },
          },
        ];
        gridApi.setColumnDefs(columnDefs);
        selectedNodeIds.forEach((nodeDetails: any) => {
          const node = gridApi.getRowNode(nodeDetails.nodeId);
          if (!!node) {
            node.data.checkingFromDetail = true;
            node.setSelected(true);
            setTimeout(() => (node.data.checkingFromDetail = false), 5);
          }
        });
        if (items.length <= 0) {
          gridApi?.showNoRowsOverlay();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, gridApi]);

    const gridOptions: GridOptions = {
      headerHeight: 32,
      groupHeaderHeight: 32,
      onGridReady,
      defaultColDef: {
        flex: 1,
        minWidth: 75,
        filter: true,
        wrapText: true,
        autoHeight: true,
        menuTabs: [],
        cellStyle: {
          wordBreak: 'break-word',
          paddingRight: '16px',
        },
      },
      loadingOverlayComponentFramework: LoadingComponent,
      overlayNoRowsTemplate: 'No Matching Items Found',
      isRowSelectable: (node: RowNode) => {
        return itemSchedulingDraftRule(node.data);
      },
      onRowSelected: (e: RowEvent) => {
        if (e.node.data?.checkingFromDetail || !e.node.data) return;
        setSelectedRowsState((prev: ISelectedRowsState) => {
          const rowIds = [...prev.rowIds];
          const selectedNodes = [...prev.selectedNodes];
          if (e.node.isSelected() && !String(e.node.id).startsWith('row-group')) {
            rowIds.push({
              nodeId: e.node.id,
              itemNumberWarehouse: e.node.data.itemNumberWarehouse,
            });
            selectedNodes.push(e.node);
          } else {
            remove(rowIds, (n: any) => n.nodeId === e.node.id);
            remove(selectedNodes, e.node);
          }
          const isChecked = rowIds.length === prev.totalCount;
          const isIndeterminate = rowIds.length > 0 && !isChecked;
          const finalizingMax = max(map(selectedNodes, 'data.daysToFinalize'));
          return {
            ...prev,
            count: rowIds.length,
            rowIds,
            isChecked,
            isIndeterminate,
            selectedNodes,
            finalizingMax,
          };
        });
      },
      frameworkComponents: {
        gridInput: GridInput,
        offerShipDateComponent: OfferShipDateComponent,
        shippingWindow: ShippingWindow,
      },
      groupSelectsChildren: true,
      groupDefaultExpanded: -1,
      rowSelection: 'multiple',
      suppressContextMenu: true,
      suppressRowClickSelection: true,
      suppressRowTransform: true,
      suppressCellSelection: true,
      suppressAggFuncInHeader: true,
      enableCellTextSelection: true,
      suppressMovableColumns: true,
      immutableData: true,
      rowBuffer: 15,
      getRowNodeId: (data: any) => `${data.itemElectId}`,
      autoGroupColumnDef: {
        headerName: 'Item',
        cellRenderer: 'agGroupCellRenderer',
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        sortable: false,
        cellClass: 'px-po-item-cell',
        cellRendererParams: {
          checkbox: true,
          suppressCount: true,
          suppressDoubleClickExpand: true,
        },
        valueGetter: (params: ValueGetterParams) => {
          if (
            tabIndexState.current !== tabIndexStates.ALL ||
            params.data.secondItemGroup.length === 1
          )
            return `${params.data.color} [${params.data.itemNumber}]`;
          const secondItemArray = params.data.secondItemGroup
            .map((item: IItems) => item.status)
            .sort(
              (a: any, b: any) =>
                orderOfItemSchedulingTabs.indexOf(a) - orderOfItemSchedulingTabs.indexOf(b)
            );
          if (secondItemArray[0] === params.data.status)
            return `${params.data.color} [${params.data.itemNumber}]`;
        },
      },
    };
    //#endregion >> grid col def and options

    return (
      <div className="ag-theme-alpine px-height-100vh" data-id="itemSetupTable">
        <AgGridReact
          gridOptions={gridOptions}
          suppressChangeDetection={false}
          suppressNoRowsOverlay={false}
        />
      </div>
    );
  }
);

export default ItemSetupGrid;
