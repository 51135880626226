import { Dispatch } from 'react';

export interface IItemScheduling {
  itemsListState: IItemSchedulingState;
  itemSchedulingActions: any;
}

export interface IItemSchedulingState {
  itemsList: IItemsList;
  uniqueCategories: any;
  uniqueWarehouses: any;
}

export interface IItemsList {
  items: IItems[] | null;
  lastUpdatedTs: string;
}
export interface IItemSchedulingActions {
  itemSchedulingSet: (
    itemsList: IItemsList | null
  ) => {
    type: string;
    itemsList: IItemsList | null;
  };
}
export interface IItems {
  capacity: string;
  carrier: string;
  category: string;
  color: string;
  grade: string;
  groupName: string;
  incrementSize: number;
  itemDescription: string;
  itemElectId: number;
  itemNumber: string;
  lockStatus: string;
  manufacturer: string;
  model: string;
  offerEndDate: string;
  offerStartDate: string;
  productFamily: string;
  protocol: string;
  quantityForecast: number;
  screenSize: string;
  shippingEndDate: string;
  shippingStartDate: string;
  status: string;
  warehouse: string;
  secondItemGroup?: IItems[];
}

export enum tabIndexStates {
  'ALL',
  'DRAFT',
  'SCHEDULED',
  'OFFERING',
  'SHIPPING',
}

export interface ISelectedRowsState {
  count: number;
  totalCount: number;
  rowIds: any[];
  isChecked: boolean;
  isIndeterminate: boolean;
  selectedNodes: any[];
  finalizingMax: number;
}

export enum IItemSchedulingTab {
  all = 'ALL',
  draft = 'DRAFT',
  scheduled = 'SCHEDULED',
  offering = 'OFFERING',
  shipping = 'SHIPPING',
  negotiating = 'NEGOTIATING',
  finalizing = 'FINALIZING',
}

export interface ItemImportValue {
  dbIncrementSize: number;
  dbItemDescription: string;
  itemNumber: string;
  itemNumberWarehouse: string;
  quantityForecast: number;
  rowNumber: number;
  status: string;
  warehouse: string;
}

interface ItemImportErrors {
  'ITEM#_INVALID': ItemImportValue[];
  WAREHOUSE_INVALID: ItemImportValue[];
  ITEM_WAREHOUSE_DOESNOT_EXIST: ItemImportValue[];
  DUPLICATE_ITEMS: ItemImportValue[];
}
interface ItemImportWarnings {
  FORECAST_QUANTITY_NOT_A_MULTIPLE_OF_INCREMENT_SIZE: ItemImportValue[];
  FORECAST_QUANTITY_INVALID: ItemImportValue[];
}
export interface ItemImportChanges {
  newItems: ItemImportValue[];
  updatedItems: ItemImportValue[];
}
export interface IImportItems {
  errors: ItemImportErrors;
  warnings: ItemImportWarnings;
  changes: ItemImportChanges;
  fileName: string;
}

export interface ITabStats {
  [IItemSchedulingTab.draft]: number;
  [IItemSchedulingTab.scheduled]: number;
  [IItemSchedulingTab.offering]: number;
  [IItemSchedulingTab.shipping]: number;
  [IItemSchedulingTab.negotiating]: number;
  [IItemSchedulingTab.finalizing]: number;
}

export interface IOfferSchedules {
  offerStartDate: string;
  offerEndDate: string;
}

export interface IShippingSchedules {
  shippingStartDate: string;
  shippingEndDate: string;
}

export interface ISchedules {
  offerStartDate: string | undefined;
  offerEndDate: string | undefined;
  shippingStartDate: string | undefined;
  shippingEndDate: string | undefined;
}

export interface IAllItemSchedules {
  distinctOverlappingOfferSchedules: IOfferSchedules[];
  distinctOverlappingShippingSchedules: IShippingSchedules[];
  itemNumberWarehouse: string;
  nonOverlappingSchedules: ISchedules[];
}

export interface IScheduleOfferDates {
  minOfferDate: string;
  maxOfferDate: string;
}

export interface IScheduleShippingDates {
  minShippingDate: string;
  maxShippingDate: string;
}

export interface IOfferTimeRange {
  offerStartTimeInSeconds: number;
  offerEndTimeInSeconds: number;
}
export interface IDraftTimeSelectorDialog {
  closeDialog: () => void;
  isDialogOpen: boolean;
  title: string;
  confirmHandler: (selectedOfferTime: IOfferTimeRange) => Promise<boolean>;
  offerTimeRange: IOfferTimeRange;
  setLoadingDialog: Dispatch<boolean>;
}
