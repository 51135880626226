import React, { useEffect, useState } from 'react';
import Dialog from '../Dialog/Dialog';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import PrimaryButton from '../AtomComponents/PrimaryButton';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import Link from '@material-ui/core/Link';

import './BulkUpdateDates.scss';

import {
  convertSecondsToTime,
  dateFormats,
  formatDateByMonthDayYear,
  formatDatesByMonthDay,
  manipulateDates,
} from '../PreOrders/Utils';
import { FormControl, InputLabel } from '@material-ui/core';
import CreateDateAnalyticsSelector from './CreateDates/CreateDateAnalyticsSelector';
import { ICreateDateRange } from '../../types/IPricing';
import moment from 'moment';
import { ISchedules } from '../../types/IItemSetup';

interface IBulkUpdateDatesPropTypes {
  title?: any;
  open?: boolean;
  selectedItemsInfo: any;
  handleClose: any;
  updateItem: any;
  selectedNodes: any;
  setLoadingDialog: any;
  daysToFinalize: number;
  offeringTimeRange?: any;
}

const BulkUpdateDates = ({
  title,
  open,
  handleClose,
  selectedItemsInfo,
  updateItem,
  selectedNodes,
  setLoadingDialog,
  daysToFinalize,
  offeringTimeRange,
}: IBulkUpdateDatesPropTypes) => {
  const {
    nonOverlappingSchedules,
    overlappingOfferSchedules,
    overlappingShippingSchedules,
    selectedItems,
  } = selectedItemsInfo;

  const [selectedDate, setSelectedDate] = useState<ISchedules | unknown>('');
  const [showCalender, setShowCalender] = useState<boolean>(false);
  const [overLapShipping, setOverLapShipping] = useState<any>();
  const [dateRange, setDateRange] = useState<ICreateDateRange>({
    offerDates: {
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    },
    shippingDates: {
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.yearMonthDate,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    },
  });

  useEffect(() => {
    const commonDateFormat = 'YYYY-MM-DD';
    const todaysDate = moment().format(commonDateFormat);
    const shippingStartFromToday = moment()
      .add(daysToFinalize + 1, 'days')
      .format('YYYY-MM-DD');

    const offerSchedules = overlappingOfferSchedules;
    const shippingSchedules = overlappingShippingSchedules;
    let offerDates = dateRange.offerDates;
    let shippingDates = dateRange.shippingDates;
    if (
      offerSchedules.length &&
      moment(offerSchedules[0].offerEndDate, commonDateFormat).isSameOrAfter(todaysDate)
    ) {
      offerDates = {
        startDate: manipulateDates({
          date: offerSchedules[0].offerEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
        endDate: manipulateDates({
          date: offerSchedules[0].offerEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
      };
    }
    if (
      shippingSchedules.length &&
      moment(shippingSchedules[0].shippingEndDate, commonDateFormat).isSameOrAfter(
        shippingStartFromToday
      )
    ) {
      shippingDates = {
        startDate: manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        }),
        endDate: manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 2 },
        }),
      };
      setOverLapShipping(
        manipulateDates({
          date: shippingSchedules[0].shippingEndDate,
          formatTo: dateFormats.yearMonthDate,
          currentFormat: dateFormats.yearMonthDate,
          manipulate: { days: 1 },
        })
      );
    }
    setDateRange({ offerDates, shippingDates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeAllDialogs = () => {
    setShowCalender(false);
    handleClose();
  };

  const saveSelectedDate = async () => {
    setLoadingDialog(true);
    const res = await saveDates(selectedDate);
    if (res) closeAllDialogs();
    setLoadingDialog(false);
  };

  const saveDates = async (selectedDates: any) => {
    const updatedStartDate = convertSecondsToTime(
      offeringTimeRange?.offerStartTimeInSeconds ?? 0,
      'YYYY-MM-DD HH:mm:ss',
      selectedDates.offerStartDate,
      'YYYY-MM-DD'
    );
    const updatedEndDate = convertSecondsToTime(
      offeringTimeRange?.offerEndTimeInSeconds ?? 0,
      'YYYY-MM-DD HH:mm:ss',
      selectedDates.offerEndDate,
      'YYYY-MM-DD'
    );
    const items: any = [];
    selectedNodes.forEach((rowNode: any) => {
      const requestItem = {
        itemElectId: rowNode.data.itemElectId,
        itemNumberWarehouse: rowNode.data.itemNumberWarehouse,
        itemNumber: rowNode.data.itemNumber,
        warehouse: rowNode.data.warehouse,
        quantityForecast: rowNode.data.quantityForecast,
        offerStartDate: updatedStartDate,
        offerEndDate: updatedEndDate,
        shippingStartDate: selectedDates.shippingStartDate,
        shippingEndDate: selectedDates.shippingEndDate,
        status: rowNode.data.status,
      };
      items.push(requestItem);
    });
    if (updateItem) {
      const res = await updateItem(items, false, false, true);
      if (res) return true;
      return false;
    }
  };

  if (showCalender) {
    return (
      <CreateDateAnalyticsSelector
        dateRangeDialog={showCalender}
        offerDates={dateRange.offerDates}
        shippingDates={dateRange.shippingDates}
        updateCustomDate={saveDates}
        clearDateRangeSelector={closeAllDialogs}
        closeDateRangeSelector={closeAllDialogs}
        setLoadingDialog={setLoadingDialog}
        overLappingShippingDates={overLapShipping}
        daysToFinalize={daysToFinalize}
      />
    );
  }

  return (
    <Dialog
      isDialogOpen={open}
      closeDialog={closeAllDialogs}
      title={title}
      fullWidth
      maxWidth="sm"
      content={
        <>
          <p>
            Selected offer date range from{' '}
            <b>{formatDateByMonthDayYear(selectedItems.offerStartDate)} </b>
            to <b>{formatDateByMonthDayYear(selectedItems.offerEndDate)}</b>
          </p>
          <p>
            Selected shipping date range from{' '}
            <b>{formatDateByMonthDayYear(selectedItems.shippingStartDate)} </b>
            to <b>{formatDateByMonthDayYear(selectedItems.shippingEndDate)} </b>
          </p>
          <Box className="margin-top-3">
            <div className="grid-x  margin-bottom-1">
              <span className="cell small-2">Offer Dates</span>
              <span className="cell small-3">Shipping Dates</span>
            </div>
            <FormControl style={{ minWidth: '40%' }}>
              {!selectedDate && (
                <InputLabel id="select-dates-label" shrink={false}>
                  Select Dates
                </InputLabel>
              )}
              <Select
                style={{ minWidth: '40%' }}
                //fullWidth
                labelId="select-dates-label"
                onChange={(e) => {
                  if (e.target.value !== undefined) setSelectedDate(e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  classes: {
                    paper: 'dates-drop-down',
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={(selected: any) => {
                  return (
                    selected !== undefined && (
                      <div className="grid-x">
                        <span className="cell small-6">
                          {formatDatesByMonthDay({
                            leftStartDate: selected.offerStartDate,
                            leftEndDate: selected.offerEndDate,
                            rightStartDate: selected.shippingStartDate,
                            rightEndDate: selected.shippingEndDate,
                          })}
                        </span>
                      </div>
                    )
                  );
                }}
                value={selectedDate}>
                {nonOverlappingSchedules?.map((date: any, index: number) => {
                  return (
                    <MenuItem key={index} value={date} className="grid-x">
                      <CheckIcon className={`${date !== selectedDate && 'display-none'}`} />
                      <span className="cell small-5">
                        {formatDateByMonthDayYear(date.offerStartDate, date.offerEndDate)}
                      </span>
                      <span className="cell small-5">
                        {formatDateByMonthDayYear(date.shippingStartDate, date.shippingEndDate)}
                      </span>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  className="grid-x"
                  onClick={async () => {
                    setShowCalender(true);
                  }}>
                  <Link className="cell margin-left-1">Create New Dates..</Link>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>
      }
      actions={
        <>
          <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
          <PrimaryButton disabled={!selectedDate} onClick={saveSelectedDate} autoFocus>
            Save
          </PrimaryButton>
        </>
      }
    />
  );
};
export default BulkUpdateDates;
